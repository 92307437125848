import { namespace } from "../../../SharedSource/Web.Core/Client/Namespace";

var ZapVariables: server.ZapVariablesViewModel = (window as any).ZapVariables;

export function antiForgeryHeaders() {
    let antiForgeryField = document.getElementsByName(ZapVariables.AntiForgeryField)[0] as HTMLInputElement;
    return antiForgeryField && antiForgeryField.value
        ? { [ZapVariables.AntiForgeryField]: antiForgeryField && antiForgeryField.value }
        : {};
}

namespace('Zap').antiForgeryHeaders = antiForgeryHeaders;
