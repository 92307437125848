import { event, events, reduce, reduced, derived } from "event-reduce";
import { FeatureSwitch } from "../../../../SharedSource/Web.Core/Client/FeatureSwitch";

@events
export class EmbeddedLoginEvents {
    ssoLoginFailed = event();
    loginPopupOpened = event();
    loginPopupClosed = event();
    loginPopupFailedToOpen = event();
    loggedIn = event();
}

export class EmbeddedLoginModel {
    events = new EmbeddedLoginEvents();

    @derived
    get isLoading() {
        return this.loginState != LoginState.None;
    }

    @derived
    get showSsoLogin() {
        return this.loginState == LoginState.SsoLoggingIn;
    }

    @reduced
    loginState = reduce(FeatureSwitch.optionEnabled("SeamlessSso") ? LoginState.SsoLoggingIn : LoginState.None, this.events)
        .on(e => e.ssoLoginFailed, current => current == LoginState.SsoLoggingIn ? LoginState.None : current)
        .on(e => e.loginPopupOpened, () => LoginState.LoginPopupOpened)
        .on(e => e.loginPopupClosed, current => current == LoginState.LoginPopupOpened ? LoginState.None : current)
        .on(e => e.loggedIn, () => LoginState.Redirecting)
        .value;

    @reduced
    error = reduce('', this.events)
        .on(e => e.loginPopupOpened, () => '')
        .on(e => e.loginPopupFailedToOpen, () => PageText.Login_PopupFailedToOpen)
        .value;
}

export enum LoginState {
    None,
    SsoLoggingIn,
    SsoLoginFailed,
    LoginPopupOpened,
    Redirecting
}