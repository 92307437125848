import '@zap/utils/lib/extensions';
import { filterObject } from "@zap/utils/lib/Object";
import { cloneReactElement, forwardRef } from "@zap/utils/lib/ReactHelpers";
import { Children, createElement, FunctionComponent, ReactElement, ReactNode, Ref } from "react";
import { classes, CSSProperties, elementStyle, StyleCollection } from 'stylemap';
import htmlTags from './HtmlTags';

type IntrinsicElementProps<Tag extends keyof JSX.IntrinsicElements | undefined> = Tag extends keyof JSX.IntrinsicElements
    ? React.ComponentProps<Tag>
    : {};

export type IStyledProps<Tag extends keyof JSX.IntrinsicElements | undefined> = IntrinsicElementProps<Tag> & {
    styles: StyleCollection;
    inline?: CSSProperties;
    tag?: keyof JSX.IntrinsicElements;
}

export const Styled = Object.assign(
    forwardRef(renderStyled),
    htmlTags.toObject(tag => tag, tag => {
        return Object.assign(forwardRef(function StyledTag(props: IStyledProps<any>, ref?: Ref<any>) {
            return renderStyled({ tag, ...props }, ref);
        }), { displayName: `Styled.${tag}` });
    })) as unknown as StyledComponent;

function renderStyled({ styles, inline, className, tag, children, ...elementProps }: IStyledProps<any> & { children?: ReactNode }, ref?: Ref<any>) {
    if (tag) {
        return createElement(tag, {
            ...styledProps(styles, className, inline, ref),
            ...elementProps
        }, children);
    } else {
        let child = Children.only(children as any);
        return cloneReactElement(child, styledProps(styles, child.className, inline, ref));
    }
}

function styledProps(styles: StyleCollection, className?: string, inline?: CSSProperties, ref?: Ref<any>) {
    return {
        className: [classes(styles), className].notFalsy().join(' '),
        style: inline && filterObject(elementStyle(inline), (_, p) => p != null),
        ref: ref
    };
}

type StyledComponent = { <Tag extends keyof JSX.IntrinsicElements | undefined>(props: IStyledProps<Tag>): ReactElement }
    & { [E in keyof JSX.IntrinsicElements]: FunctionComponent<IStyledProps<E>> }