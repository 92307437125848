import { cloneReactElement, combineRefs, forwardRef } from "@zap/utils/lib/ReactHelpers";
import { Ref, useEffect, useRef } from "react";

export interface IAutoFocusProps {
    enabled?: boolean;
    recurring?: boolean
    children: React.ReactElement;
}

const focusableElements = `
    a:not([disabled]), 
    button:not([disabled]), 
    input[type=text]:not([disabled]), 
    input[type=search]:not([disabled]), 
    [tabindex]:not([disabled]), 
    textarea:not([disabled]),
    [contenteditable]:not([contenteditable=false]):not([disabled])
`;

export const AutoFocus = forwardRef(function AutoFocus({ enabled, children, recurring }: IAutoFocusProps, ref: Ref<Element>) {
    let focusRef = useAutoFocus(enabled, recurring);
    return cloneReactElement(children, { ref: combineRefs(ref, focusRef) });
});

export function useAutoFocus<TElement extends HTMLElement>(enabled: boolean = true, recurring: boolean = false, scroll: boolean = false) {
    let ref = useRef<TElement>(null);
    let hasFocused = useRef(false);

    useEffect(() => {
        if (enabled && ref.current && (!hasFocused.current || recurring)) {
            let focusable = focusableElementsIn(ref.current)[0];
            if (!focusable?.contains(document.activeElement))
                focusable?.focus({ preventScroll: !scroll });
            hasFocused.current = true;
        }
    });

    return ref;
}

export function focusableElementsIn(root: HTMLElement | null | undefined) {
    return (root?.matches(focusableElements) ? [root] : [])
        .concat(Array.from(root?.querySelectorAll<HTMLElement>(focusableElements) ?? []));
}

export function closestFocusableElement(element: HTMLElement | null) {
    return element?.closest(focusableElements) ?? null;
}