import { Column } from "@zap/ui/lib/Box";
import { Button } from "@zap/ui/lib/Button";
import { defaultFormWidth } from "@zap/ui/lib/Form";
import { HelperText } from "@zap/ui/lib/HelperText";
import { useTimeout } from "@zap/ui/lib/Timeout";
import { useEffect, useRef } from "react";
import "../../../../SharedSource/Web.Core/Client/Text";
import { EmbeddedLoginModel, LoginState } from "./EmbeddedLoginModel";
import { LoginContainer } from "./LoginContainer";
import { standardSpacing } from "@zap/ui/lib/Sizes";
import { reactive } from "event-reduce-react";
import { SeamlessSsoBootstrap } from "./SeamlessSsoBootstrap";

export interface IEmbeddedLoginFormProps {
    model: EmbeddedLoginModel;
    returnUrl: string;
    challengeUrl: string;
    headerImage: string;
    isCustomLogo: boolean;
}

export const EmbeddedLoginForm = reactive(function EmbeddedLoginForm({ model, returnUrl, challengeUrl, headerImage, isCustomLogo }: IEmbeddedLoginFormProps) {
    let [setTimeout, clearTimeout] = useTimeout();

    useEffect(() => {
        window.addEventListener('message', onWindowMessage);
        return () => window.removeEventListener('message', onWindowMessage);
    }, []);

    let loginWindow = useRef(null as Window | null);

    return model.showSsoLogin
        ? <SeamlessSsoBootstrap model={model} returnUrl={returnUrl} challengeUrl={challengeUrl} />
        : <LoginContainer headerImage={headerImage} isCustomLogo={isCustomLogo}>
            <Column noSpacing innerPadding>
                <Button onClick={() => openLoginWindow()} isLoading={model.isLoading} contained>
                    {model.loginState == LoginState.Redirecting
                        ? PageText.Label_Loading
                        : PageText.Login_Button}
                </Button>
                <HelperText errorText={model.error} />
            </Column>
        </LoginContainer>

    function openLoginWindow() {
        let width = defaultFormWidth + 2 * standardSpacing;
        let height = 648; // Just what I measured it to be with a login failure message; depends on content - soxtoby

        let left = window.screenLeft + Math.floor((screen.width - width) / 2);
        let top = Math.floor((screen.height - height) / 2);

        loginWindow.current = window.open(location.href + '&isPopup=true', 'login', `dialog,screenY=${top},screenX=${left},width=${width},height=${height}`);

        if (loginWindow.current) {
            model.events.loginPopupOpened();
            setTimeout(pollForClose, windowClosePollInterval);
        } else {
            model.events.loginPopupFailedToOpen();
        }
    }

    function pollForClose() {
        if (!loginWindow.current || loginWindow.current.closed)
            model.events.loginPopupClosed();
        else
            setTimeout(pollForClose, windowClosePollInterval);
    }

    function onWindowMessage(event: MessageEvent) {
        if (event.data && event.data.loginComplete) {
            model.events.loggedIn();
            location.href = returnUrl;
            clearTimeout();
        }
    }
});

const windowClosePollInterval = 1000;