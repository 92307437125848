import { dataProps } from "@zap/utils/lib/ReactHelpers";
import { noSpacing } from "./Box";
import { duration, highlightColor, lightHighlightColor, roundedBorders } from "./CommonStyles";
import { subGrid } from "./Sizes";
import { StyleCollection, Styled, Styles, animation, style, transition, variable } from "./styling";

export interface IProgressProps {
    max?: number;
    value?: number;
    indeterminate?: boolean;
    backgroundColor?: Styles['color'],
    foregroundColor?: Styles['color'],
    square?: boolean;
    styles?: StyleCollection;
}

export function Progress(props: IProgressProps) {
    let backgroundColor = props.backgroundColor ?? lightHighlightColor;
    let foregroundColor = props.foregroundColor ?? highlightColor;
    let barWidth = props.indeterminate ? 0 : (100 * props.value! / props.max!) + '%';

    return <Styled.div
        styles={[
            progress,
            !props.square && roundedBorders,
            props.indeterminate && indeterminate,
            noSpacing,
            props.styles
        ]}
        inline={{
            ...background.set(backgroundColor),
            ...foreground.set(foregroundColor)
        }}
        {...dataProps(props)}
    >
        <Styled.div styles={bar} inline={{ width: barWidth }} />
    </Styled.div>;
}

let background = variable('color', 'progress-background');
let foreground = variable('color', 'progress-foreground');

let progress = style('progress', {
    position: 'relative',
    height: subGrid,
    backgroundColor: background,
    overflow: 'hidden',
    transition: transition('background-color', duration.small, 'ease')
});

let bar = style('progress-bar', {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 0,
    backgroundColor: foreground,
    transition: transition('background-color', duration.small, 'ease')
});

let indeterminate = style('progress-indeterminate', {
    '::before': {
        content: '""',
        position: 'absolute',
        backgroundColor: foreground,
        top: 0,
        left: 0,
        bottom: 0,
        willChange: 'left, right',
        animation: animation({
            0: {
                left: '-35%',
                right: '100%'
            },
            60: {
                left: '100%',
                right: '-90%'
            },
            100: {
                left: '100%',
                right: '-90%'
            }
        }, '2.1s', 'cubic-bezier(0.65, 0.815, 0.735, 0.395)', undefined, 'infinite')
    },

    '::after': {
        content: '""',
        position: 'absolute',
        backgroundColor: foreground,
        top: 0,
        left: 0,
        bottom: 0,
        willChange: 'left, right',
        animation: animation({
            '0%': {
                left: '-200%',
                right: '100%'
            },
            '60%': {
                left: '107%',
                right: '-8%'
            },
            '100%': {
                left: '107%',
                right: '-8%'
            }
        }, '2.1s', 'cubic-bezier(0.165, 0.84, 0.44, 1)', '1.15s', 'infinite')
    }
});
