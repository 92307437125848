import { IAsyncEvent, IObservable, reduce } from "event-reduce";
import { ObservableOperation, isObservable, merge } from "event-reduce/lib/observable";
import { ObservableValue } from "event-reduce/lib/observableValue";
import { arraysEqual } from "./Array";
import { noop } from "./Function";
import { LoadState, ObservableLoadState } from "./LoadState";
import { mapValues } from "./Object";
import { IStreamEvent } from "./StreamEvent";

export function setFrom<T>(initial: T, setterEvent: IObservable<T>) {
    return reduce(initial).on(setterEvent, (_, value) => value).value;
}

export function setAndReset<T>(initial: T, setterEvent: IObservable<T>, resetEvent: IObservable<any>): T {
    return reduce(initial)
        .on(setterEvent, (_, value) => value)
        .on(resetEvent, () => initial)
        .value;
}

export interface ILoadStateMapOptions<Result, Context> {
    load: IAsyncEvent<Result, Context>;
    key: (context: Context) => string;
    stale?: (context: Context) => IObservable<any>;
}

export function loadStateMap<Result, Context>({ load, key, stale }: ILoadStateMapOptions<Result, Context>) {
    return reduce({} as { [key: string]: LoadState })
        .on(load.started, (all, { promise, context }) => ({ ...all, [key(context)]: new LoadState(load.filter(c => c == context), stale?.(context), promise) }))
        .value;
}

export function observableLoadStateMap<Item, Context>(event: IStreamEvent<Item, Context>, getKey: (context: Context) => string) {
    return reduce({} as { [key: string]: ObservableLoadState })
        .on(event.started, (all, { stream, context }) => ({ ...all, [getKey(context)]: new ObservableLoadState(event.filter(c => c == context), stream) }))
        .value;
}

export function resultMap<Result, Context>(event: IAsyncEvent<Result, Context>, getKey: (context: Context) => string, defaultValue?: Result) {
    return resultMapReduction(event, getKey, defaultValue).value;
}

export function resultMapWithReset<Result, Context>(event: IAsyncEvent<Result, Context>, reset: IObservable<any>, getKey: (context: Context) => string, defaultValue?: Result) {
    return resultMapReduction(event, getKey, defaultValue)
        .on(reset, () => ({}))
        .value;
}

export function resultMapReduction<Result, Context>(event: IAsyncEvent<Result, Context>, getKey: (context: Context) => string, defaultValue?: Result) {
    let reduction = reduce({} as Record<string, Result>);
    if (typeof defaultValue != 'undefined')
        reduction = reduction.on(event.started, (all, { context }) => ({ ...all, [getKey(context)]: defaultValue }));
    return reduction.on(event.resolved, (all, { result, context }) => ({ ...all, [getKey(context)]: result }));
}

/** Equality function for derived arrays. */
export function arrayValue(prev: unknown[], next: unknown[]) {
    return prev == next
        || prev && next && arraysEqual([prev, next]);
}

export function augmentEvents<Events extends object>(events: Events) {
    let externalEvents = mapValues(events, (event, key) => isObservable(event) ? new SwitchingObservable(() => `${String(key)}.external`) : undefined);
    let combinedEvents = mapValues(events, (event, key) => isObservable(event) ? merge([event, externalEvents[key]!]) : undefined);
    return Object.assign(combinedEvents, {
        setExternalEvents: (newExternalEvents: Partial<Events> | undefined) => {
            for (let key of Object.keys(externalEvents))
                externalEvents[key as keyof Events]?.switchTo(newExternalEvents?.[key as keyof Events] as IObservable<any>);
        }
    }) as AugmentedEvents<Events>;
}

type AugmentedEvents<Events> =
    & ObservableProperties<Events>
    & { setExternalEvents: (externalEvents: Partial<ObservableProperties<Events>> | undefined) => void };

type ObservableProperties<Events> = {
    [K in keyof Events]: Events[K] extends IObservable<infer T> ? IObservable<T> : never;
};

export class SwitchingObservable<T> extends ObservableOperation<T> {
    private readonly _observables: ObservableValue<IObservable<T>>;

    constructor(getDisplayName: () => string) {
        let observables = new ObservableValue<IObservable<T>>(() => `${this.displayName}.observables`, emptyObservable);

        super(getDisplayName, [observables], observer => {
            let unsubscribePrevious = noop;
            return observables.subscribe(o => {
                unsubscribePrevious();
                unsubscribePrevious = o.subscribe(observer.next);
            }, getDisplayName);
        });

        this._observables = observables;
    }

    switchTo(observable: IObservable<T> | null | undefined) { this._observables.setValue(observable ?? emptyObservable); }
}

export const emptyObservable = new ObservableOperation<any>(() => 'empty', [], () => noop);