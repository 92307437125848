import { createContext } from "react";
import { Styles, animation, style, variable } from "stylemap";
import { PaletteName, blue, colorPalettes, green, purple, red, yellow } from "./Colors";
import { halfSpacing } from "./Sizes";
import { Elevation, shadow } from "./shadow";
import { border } from "./styling";

export const lightBarbsColor = variable('color', 'lightBarbs');
export const darkBarbsColor = variable('color', 'darkBarbs');
export const highlightColor = variable('color', 'highlightColor');
export const darkHighlightColor = variable('color', 'darkHighlightColor');
export const lightHighlightColor = variable('color', 'lightHighlightColor');
export const hoverHighlightColor = variable('color', 'hoverHighlightColor');
export const selectedHighlightColor = variable('color', 'selectedHighlightColor');
export const iconPrimaryColor = variable('color', 'iconPrimaryColor', purple[700]);
export const iconSecondaryColor = variable('color', 'iconSecondaryColor', yellow[700]);
export const iconComponentColor = variable('color', 'iconComponentColor', '#8c8c8c');
export const errorColor = variable('color', 'errorColor', red[600]);
export const successColor = variable('color', 'successColor', green[700]);
export const warningColor = variable('color', 'warningColor', yellow[600]);
export const blackColor = variable('color', 'blackColor', '#0c0c0c');
export const greyColor = variable('color', 'greyColor', '#5c5c5c');
export const whiteColor = variable('color', 'whiteColor', '#fcfcfc');
export const darkGreyColor = variable('color', 'darkGreyColor', '#333333');
export const lightGreyColor = variable('color', 'lightGreyColor', '#e6e6e6');
export const lightGreyBorderColor = variable('color', 'lightGreyBorderColor', '#d9d9d9');
export const lightGreyFocusColor = variable('color', 'lightGreyFocusColor', '#d4d4d4');
export const lightGreyFontColor = variable('color', 'lightGreyFontColor', '#999999');
export const blueColor = variable('color', 'blueColor', blue[700]);
export const linkColor = variable('color', 'linkColor', purple[800]);
export const linkHoverColor = variable('color', 'linkHoverColor', purple[900]);

export const interactiveForegroundColor = variable('color', 'interactiveForeground', '#757575');
export const interactiveForegroundHoverColor = variable('color', 'interactiveForegroundHover', '#212121');
export const disabledForeground = variable('color', 'disabledForeground', '#b8b8b8');
export const disabledBackground = variable('color', 'disabledBackground', '#ebebe4');

export const pageBackgroundColor = variable('color', 'pageBackground', '#f0f0f0');
export const paneBackgroundColor = variable('color', 'paneBackground', '#ffffff');
export const toolWindowBackgroundColor = variable('color', 'toolWindowBackground', '#ffffff');
export const toolWindowSectionHeaderColor = variable('color', 'toolWindowSectionHeader', lightGreyColor);
export const toolWindowSectionHeaderBorderColor = variable('color', 'toolWindowSectionHeaderBorder', lightGreyBorderColor);
export const dialogBackgroundColor = variable('color', 'dialogBackground', '#ffffff');

export const formControlBackground = variable('color', 'formControlBackground', '#f5f5f5');
export const formControlBorder = variable('color', 'formControlBorder', '#ececec');
export const formControlHover = variable('color', 'formControlHover', '#ececec');
export const formControlHoverBorder = variable('color', 'formControlHoverBorder', '#e0e0e0');
export const formControlFocus = variable('color', 'formControlFocus', '#fafafa');

export const colorSchemes = Object.entries(colorPalettes).toObject(([name]) => name, ([, colors]) => ({
    ...lightBarbsColor.set(colors[200]),
    ...darkBarbsColor.set(colors[300]),
    ...highlightColor.set(colors[600]),
    ...darkHighlightColor.set(colors[800]),
    ...lightHighlightColor.set(colors[100]),
    ...hoverHighlightColor.set(colors[50]),

    ...selectedHighlightColor.set(lightHighlightColor),
} satisfies Styles)) as Record<PaletteName, Styles>;

export const darkModeStyles = style('is-darkMode', {
    ...pageBackgroundColor.set('#262626'),
    ...paneBackgroundColor.set('#121212'),
});

export const semiBoldFontWeight = 600;

export const duration = {
    /** Small movements, like a switch */
    small: 100,
    /** Things appearing out of nowhere, like a menu */
    appear: 120,
    /** Larger movements */
    medium: 250,
    /** Sliding in from the edge */
    slideIn: 250,
    /** Sliding out to the edge */
    slideOut: 200,
};

export const standardBorderColor = lightGreyColor;
export const standardBorderWidth = 1;
export const standardBorderStyle = 'solid';
export const standardBorder = (color: Styles['color'] = standardBorderColor) => border(standardBorderWidth, standardBorderStyle, color);
export const roundedBorderRadius = 2;
export const roundedBorders = style('roundedBorders', { borderRadius: roundedBorderRadius });


export const uninteractiveOpacity = 0.8;
export const interactiveOpacity = 0.56;
export const hoverOpacity = 0.7;
export const disabledOpacity = 0.26;
export const hoverBackgroundOpacity = 0.06;
export const focusBackgroundOpacity = 0.12;
export const activeBackgroundOpacity = 0.2;
export const focusRingOpacity = 0.4;

export const fontSizes = {
    small: 10,
    label: 12,
    default: 14,
    headingSmall: 14,
    headingLarge: 18
}

/** Manually measured heights of default font at standard font sizes. */
export const defaultFontHeights = {
    small: 13.5,
    label: 16,
    default: 19,
    headingSmall: 19,
    headingLarge: 24
}

export const defaultFontColor = style('defaultFontColor', { color: blackColor });
export const labelFontColor = style('labelFontColor', { color: greyColor });
export const lightFontColor = style('lightFontColor', { color: lightGreyFontColor });

export const labelFontSize = style('labelFontSize', { fontSize: fontSizes.label });
export const defaultFontSize = style('defaultFontSize', { fontSize: fontSizes.default });
export const smallHeadingFontSize = style('smallHeadingFontSize', { fontSize: fontSizes.headingSmall });
export const largeHeadingFontSize = style('largeHeadingFontSize', { fontSize: fontSizes.headingLarge });

export const lineHeight = 1.2;

export const defaultFontFamily = style('defaultFontFamily', { fontFamily: '"Open Sans", "Segoe UI", Helvetica, Tahoma, sans-serif' });

export const defaultFont = style('defaultFont', { ...defaultFontFamily, ...defaultFontSize });
export const labelFont = style('labelFont', { ...defaultFontFamily, ...labelFontSize });

export const inlineBlock = style('inlineBlock', { display: 'inline-block' });

export const hidden = style('hidden', { visibility: 'hidden' });

export const pageContentWidth = '1200px';

export const truncateText = style('truncateText', {
    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    $: {
        'body &': { // Just to override BI's .reactComponent * { flex-shrink: 0; }
            flexShrink: 1,
        }
    }
});

export const truncateTextRtl = style('truncateTextRtl', {
    ...truncateText,
    direction: 'rtl'
});

export const hideIfEmpty = style('hideIfEmpty', {
    ':empty': { display: 'none' }
});

export const card = style('card', {
    ...roundedBorders,
    ...shadow(Elevation.Card),
    background: paneBackgroundColor
});

export const zIndexes = {
    belowPage: -1,
    page: 0,
    abovePage: 1,
    popup: 400,
    tooltip: 700,
    loadingOverlay: 800,
    notification: 900
}

interface IHeightContext {
    zIndex?: number;
}

export const HeightContext = createContext<IHeightContext>({
    zIndex: undefined
});


export const fadeInAnimation = style('fadeIn', {
    animation: {
        keyframes: {
            from: { opacity: 0 },
            to: { opacity: 1 }
        },
        animationDuration: duration.small
    }
});

export const fadeOutAnimation = style('fadeOut', {
    animation: animation({
        from: { opacity: 1 },
        to: { opacity: 0 }
    }, duration.small, 'ease')
});

export const flexGrow = style('flexGrow', {
    flexGrow: 1
});

export const flexShrink = style('flexShrink', {
    flexShrink: 1
});

export function indicatorDot(bottom: number, right: number, color: Styles['color']): Styles {
    return {
        '::after': {
            content: '""',
            position: 'absolute',
            width: halfSpacing,
            height: halfSpacing,
            borderRadius: '50%',
            bottom,
            right,
            background: color,
        }
    };
}