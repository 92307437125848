import { cloneReactElement, combineRefs, forwardRef } from "@zap/utils/lib/ReactHelpers";
import { ReactElement, Ref } from "react";
import * as ReactDOM from "react-dom";
import { useDomScopeChild } from "./DomScope";

export const Portal = forwardRef(function Portal({ container, children }: { container: HTMLElement, children: ReactElement }, ref: Ref<any>) {
    let scopeRef = useDomScopeChild();
    let child = cloneReactElement(children, { ref: combineRefs(ref, scopeRef) });
    return ReactDOM.createPortal(child, container);
});
