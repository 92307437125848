export let gridSize = 8;
export let subGrid = gridSize * 0.5;
export let tinyItem = gridSize * 2;
export let smallItem = gridSize * 3;
export let mediumItem = gridSize * 4;
export let largeItem = gridSize * 5;
export let extraLargeItem = gridSize * 8;

export let standardSpacing = gridSize * 2;
export let halfSpacing = standardSpacing / 2;
export let standardFormControlHeight = mediumItem;

export let maxTabWidth = gridSize * 25;

export let narrowColumn = gridSize * 32;
export let singleColumn = gridSize * 48;
export let doubleColumn = singleColumn * 2 + standardSpacing;

export let StandardSizes = ['small', 'medium', 'large'] as const;
export type StandardSize = typeof StandardSizes[number];