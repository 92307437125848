import { extendPrototype } from "./ExtensionHelpers";
import { negate as staticNegate } from "./Function";

let functionExtensions = {
    negate
};
extendPrototype(Function, functionExtensions);

type FunctionExtensions = typeof functionExtensions;
declare global { interface Function extends FunctionExtensions { } }

// Implementations below:

export function negate<Fn extends (...args: any[]) => boolean>(this: Fn) {
    return staticNegate(this);
}