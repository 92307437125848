import { extendPrototype } from "./ExtensionHelpers";

let stringExtensions = {
    trimChars
};
extendPrototype(String, stringExtensions);

type StringExtensions = typeof stringExtensions;
declare global { interface String extends StringExtensions { } }

// Implementations below:

export function trimChars<Str extends string, Char extends string>(this: Str, ...chars: Char[]): Trimmed<Str, Char> {
    let str = this as string;
    let trimChar: string | undefined;

    while (trimChar = chars.find(c => str.startsWith(c)))
        str = str.slice(trimChar.length);

    while (trimChar = chars.find(c => str.endsWith(c)))
        str = str.slice(0, -trimChar.length);

    return str as Trimmed<Str, Char>;
}

type Trimmed<Str, Char extends string> =
    Str extends `${Char}${infer Rest}` ? Trimmed<Rest, Char>
    : Str extends `${infer Rest}${Char}` ? Trimmed<Rest, Char>
    : Str;