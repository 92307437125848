import { combineRefs, IChildren, refFn, useEventListener } from "@zap/utils/lib/ReactHelpers";
import { createContext, forwardRef, ReactElement, Ref, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { scrollIntoView } from "./DomHelpers";
import { DomRegistrar, useDomScope } from "./DomScope";
import { Sides } from "./Side";
import { Wrapper } from "./Wrapper";

export interface IAutoScrollProps {
    enabled?: boolean;
    children: ReactElement;
}

export function AutoScroll({ enabled, children }: IAutoScrollProps) {
    let ref = useScrollTo(enabled ?? false);
    return <Wrapper ref={ref}>{children}</Wrapper>
}

export function useScrollTo<TElement extends Element>(scroll: boolean, insets?: Partial<Sides<number>>) {
    let ref = useRef<TElement>(null);
    let context = useContext(ScrollContext);
    insets ??= context;

    useEffect(() => {
        if (scroll && ref.current)
            scrollIntoView(ref.current, insets);
    }, [scroll]);

    return refFn(ref);
}

export const ScrollContext = createContext<Sides<number>>({ left: 0, right: 0, top: 0, bottom: 0 });

export function useIsScrolled<TElement extends HTMLElement>() {
    let ref = useRef<TElement>(null);
    let [scrolled, setScrolled] = useState({ fromLeft: false, fromRight: false, fromTop: false, fromBottom: false });

    useEventListener<'scroll', TElement>('scroll', updateScroll, {}, ref);
    useLayoutEffect(updateScroll);

    return [scrolled, ref] as const;

    function updateScroll() {
        if (ref.current) {
            let fromLeft = ref.current.scrollLeft > 0;
            let fromRight = ref.current.scrollLeft + ref.current.clientWidth < ref.current.scrollWidth;
            let fromTop = ref.current.scrollTop > 0;
            let fromBottom = ref.current.scrollTop + ref.current.clientHeight < ref.current.scrollHeight;
            if (fromLeft != scrolled.fromLeft
                || fromRight != scrolled.fromRight
                || fromTop != scrolled.fromTop
                || fromBottom != scrolled.fromBottom
            ) {
                setScrolled({ fromLeft, fromRight, fromTop, fromBottom });
            }
        }
    }
}

export interface IScrollOutsideProps extends IChildren {
    onScroll(e: Event): void;
    enabled?: boolean;
}

export const ScrollOutside = forwardRef(function ScrollOutside({ onScroll, enabled, children }: IScrollOutsideProps, ref: Ref<Element>) {
    let domRegistrar = useScrollOutside(onScroll, enabled ?? false);
    let childRef = domRegistrar.useChildRef();
    return <DomRegistrar.Provider value={domRegistrar}>
        <Wrapper ref={combineRefs(ref, childRef)}>
            {children}
        </Wrapper>
    </DomRegistrar.Provider>
});

export function useScrollOutside(onScrollOutside: (e: Event) => void, enabled: boolean) {
    let domScope = useDomScope();

    useEffect(() => {
        if (enabled) {
            document.addEventListener('scroll', onScroll, true);
            return () => document.removeEventListener('scroll', onScroll, true);
        }
    }, [onScrollOutside, enabled]);

    return domScope.registrar;

    function onScroll(e: Event) {
        if (domScope.elements.none(el => el.contains(e.target as HTMLElement)))
            onScrollOutside(e);
    }
}