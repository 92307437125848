import { cloneReactElement, combineHandlers, combineRefs, forwardRef, useAsyncState } from "@zap/utils/lib/ReactHelpers";
import { useEffect, useRef, useState } from "react";
import { Column, Row } from "./Box";
import { ClickOutside } from "./ClickOutside";
import { blackColor, defaultFont, defaultFontColor, fadeInAnimation, roundedBorders, standardBorder, zIndexes } from "./CommonStyles";
import { bottomDivider } from "./Divider";
import { SmallHeading } from "./Headings/Headings";
import { isHoverSupported, useHover } from "./Hover";
import { Icon } from "./Icons/Icon";
import { Loading } from "./Loading";
import { LocalText } from "./Localization";
import { Alignment, IPopupAnchorContext, Popup, getAnchorContext as createAnchor, useMouseAnchor } from "./Popup";
import { Side } from "./Side";
import { doubleColumn, halfSpacing } from "./Sizes";
import { useTimeout } from "./Timeout";
import { Elevation } from "./shadow";
import { Styled, style } from "./styling";

export interface ITooltipContentProps {
    title?: React.ReactNode;
    content?: React.ReactNode;
    /** @deprecated Override Localization/LocalText.HelpLink */
    helpText?: string;
    helpUrl?: string;
    minWidth?: number;

    getContent?(abort: AbortSignal): PromiseLike<ITooltipContentProps>;
}

export interface ITooltipBehaviorProps {
    position?: Side | 'pointer';
    align?: Alignment;
    anchor?: IPopupAnchorContext;
    quick?: boolean;
    onShow?(): void;
}

export interface ITooltipProps extends ITooltipBehaviorProps, ITooltipContentProps {
    children: React.ReactElement;
}

export const Tooltip = forwardRef(function Tooltip(props: ITooltipProps, outerRef: React.Ref<any>) {
    let childRef = useRef<HTMLElement>(null);
    let popupRef = useRef<HTMLDivElement>(null);

    let mouseAnchor = useMouseAnchor(true);

    let alignAnchor = props.position == 'pointer' ? mouseAnchor : createAnchor(childRef);
    let positionAnchor = props.anchor ?? alignAnchor;

    let child = props.children
        ? cloneReactElement(props.children, {
            ref: combineRefs(outerRef, childRef),
            onMouseDown: combineHandlers(onSourceMousedown, props.children.props.onMouseDown),
            [dataTooltip]: props.content
        })
        : null;

    let [isHovering, noAnimation, hide] = useHoverScrubbing([childRef, popupRef], props.quick ? 200 : 900, 50);

    let side = props.position == 'pointer' ? 'bottom' : props.position;
    let align = props.align ?? (props.position == 'pointer' ? 'start' : 'center');

    let [asyncContentProps, setAsyncContentProps, isLoading, error] = useAsyncState<ITooltipContentProps>({});
    useEffect(() => {
        if (isHovering) {
            props.onShow?.();

            if (props.getContent) {
                let abortController = new AbortController();
                setAsyncContentProps(props.getContent(abortController.signal));
                return () => abortController.abort();
            }
        }
    }, [isHovering]);

    let contentProps = props.getContent ? asyncContentProps : props;

    let showTooltip = isHovering
        && (!!contentProps.content || isLoading)
        && !tooltipSuppression.shouldSuppress;

    return <>
        {child}
        <Popup elevation={Elevation.None} zIndex={zIndexes.tooltip} anchorAlign={alignAnchor} anchorPosition={positionAnchor} anchorOffset={halfSpacing} show={showTooltip} side={side} align={align} minWidth={contentProps.minWidth ?? 50} noAnimation={noAnimation} fixed>
            <ClickOutside onClick={hide} ref={popupRef}>
                <Column spacing="half" styles={[tooltip, !noAnimation && fadeInAnimation]}>
                    {isLoading
                        ? <Loading />
                        : <>
                            {contentProps.title && <SmallHeading text={contentProps.title} />}
                            <Styled.div styles={typeof contentProps.content == 'string' && tooltipText}>{contentProps.content}</Styled.div>
                            {contentProps.helpUrl &&
                                <>
                                    <Row styles={bottomDivider} />
                                    <Row>
                                        <a href={contentProps.helpUrl} target="_blank">
                                            <Row spacing="half">
                                                <Icon name="helpOutline" size="tiny" />
                                                <span>{contentProps.helpText ?? LocalText.HelpLink}</span>
                                            </Row>
                                        </a>
                                    </Row>
                                </>
                            }
                        </>}
                </Column>
            </ClickOutside>
        </Popup>
    </>;

    function onSourceMousedown(event: React.MouseEvent) {
        if (event.button == 0)
            hide();
    }
});

function useHoverScrubbing(elementRefs: React.RefObject<HTMLElement>[], showDelayMs: number, hideDelayMs: number) {
    if (!isHoverSupported)
        showDelayMs = hideDelayMs = 0;

    let [setTimeout, clearTimeout] = useTimeout();

    let [isHovering, setIsHovering] = useState(false);
    let [immediate, setImmediate] = useState(false);

    useEffect(() =>
        () => {
            if (stopHoveringImmediately === stopHovering)
                stopHoveringImmediately = null; // Prevent state updates if this component is unmounted 
        }
        , []);

    useHover(elementRefs,
        () => {
            if (isHovering)
                return clearTimeout(); // Mouse out, then back in before timeout

            if (stopHoveringImmediately) { // Started hovering again before previous tooltip was hidden
                setTimeout(() => {
                    if (stopHoveringImmediately)
                        stopHoveringImmediately();
                    startHovering(true);
                }, hideDelayMs);
            } else {
                setTimeout(() => startHovering(false), showDelayMs); // First hover
            }
        },
        () => {
            if (isHovering)
                setTimeout(stopHovering, hideDelayMs); // Moused out after tooltip shown
            else
                clearTimeout(); // Moused out before tooltip had a chance to be shown
        });

    return [isHovering, immediate, stopHovering] as const;

    function startHovering(immediate: boolean) {
        setIsHovering(true);
        setImmediate(immediate);
        stopHoveringImmediately = stopHovering;
    }

    function stopHovering() {
        setIsHovering(false);
        stopHoveringImmediately = null;
        clearTimeout();
    }
}

let stopHoveringImmediately = null as (() => void) | null;

let tooltip = style('tooltip', {
    ...defaultFont,
    ...defaultFontColor,
    ...roundedBorders,
    border: standardBorder(blackColor),
    padding: halfSpacing,
    background: 'white',
    maxWidth: doubleColumn
});

export let tooltipText = style('tooltip-text', {
    whiteSpace: 'pre-line'
});

export const dataTooltip = 'data-tooltip';

class TooltipSuppressor {
    private _suppressionEnabled = false;
    private _isOverridden = false;

    constructor() {
        document.addEventListener('keydown', event => {
            if (event.key == 'Control')
                this._isOverridden = true;
        });

        document.addEventListener('keyup', event => {
            if (event.key == 'Control')
                this._isOverridden = false;
        });
    }

    suppress(suppress: boolean) {
        this._suppressionEnabled = suppress;
    }

    get shouldSuppress() { return this._suppressionEnabled && !this._isOverridden; }
}

export const tooltipSuppression = new TooltipSuppressor();