import { halfSpacing, standardSpacing } from "./Sizes";
import { defaultPx, Styles } from "./styling";

export let defaultLabelHeight = standardSpacing;
export let defaultDescenderHeight = 3;

/** Generates styles for a label that ensures descenders are rendered without affecting layout */
export function standardLabelStyle(fontSize: number, totalHeight: Styles['height'] = defaultLabelHeight): Styles {
    let lineHeight = Number.isNaN(parseInt(totalHeight.toString()))
        ? totalHeight
        : defaultPx(totalHeight);

    return {
        fontSize,
        height: totalHeight,
        lineHeight,
        marginLeft: halfSpacing
    };
}
