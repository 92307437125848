import { range } from "./Array";

export type Comparator<T> = (a: T, b: T) => number;

export function ascending<T>(): Comparator<T>;
export function ascending<T, U>(selector: (item: T) => U): Comparator<T>;
export function ascending<T, U>(selector: (item: T) => U = (i => i as any)) {
    return (a: T, b: T) => {
        let aVal = selector(a);
        let bVal = selector(b);
        if (Array.isArray(aVal) && Array.isArray(bVal))
            return arraysAscending(aVal, bVal);
        if (typeof aVal == 'string' && typeof bVal == 'string')
            return aVal.localeCompare(bVal);
        if (aVal != null && bVal == null)
            return 1;
        if (aVal == null && bVal != null)
            return -1;
        return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
    }
}

function arraysAscending(arrayA: unknown[], arrayB: unknown[]) {
    let indexComparators = range(Math.max(arrayA.length, arrayB.length))
        .map(i => ascending<unknown[], unknown>(arr => arr[i]));
    return compareMultiple(indexComparators)(arrayA, arrayB);
}

export function descending<T>(): Comparator<T>;
export function descending<T, U>(selector: (item: T) => U): Comparator<T>;
export function descending<T, U>(selector: (item: T) => U = (i => i as any)) {
    return reverse(ascending(selector));
}

export function reverse<T>(comparator: Comparator<T>) {
    return (a: T, b: T) => -comparator(a, b);
}

export function unordered<T>(a: T, b: T) { return 0; }

export function compareMultiple<T>(comparators: Comparator<T>[]) {
    return (a: T, b: T) => {
        for (let comparator of comparators) {
            let result = comparator(a, b);
            if (result)
                return result;
        }
        return 0;
    }
}