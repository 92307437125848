import { enableDebugging } from "flippo-react";
import { namespace } from "./Namespace";

export const FeatureSwitch = {
    optionEnabled(name: string): boolean {
        return (window as any).AvailableFeatureSwitches[name.toLowerCase()];
    },

    setOption(name: string, value: boolean): void {
        (window as any).AvailableFeatureSwitches[name.toLowerCase()] = value;
        updateClientBehavior();
    }
}

namespace("Zap").FeatureSwitch = FeatureSwitch

updateClientBehavior();

function updateClientBehavior() {
    enableDebugging(FeatureSwitch.optionEnabled('FlippoDebugging'));
}