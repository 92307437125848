import { asyncEvent, derived, event, IAsyncObservables, IObservable, reduce, reduced } from "event-reduce";
import { Observable } from "rxjs";
import "./ArrayExtensions";
import { IStreamEventObservables } from "./StreamEvent";

export interface IException {
    message: string;
    innerException?: IException;
    $type?: string;
}

export interface ILoadState {
    /** Returns true if the load state is uninitialized or stale. */
    isDubious: boolean;
    isLoading: boolean;
    hasEverLoaded: boolean;
    error: IException | undefined;
}

export class LoadState implements ILoadState {
    constructor(
        private _event: IAsyncObservables<any, any>,
        private _stale: IObservable<any> = event(),
        private _initialPromise?: PromiseLike<any>
    ) { }

    @derived
    get isDubious() {
        return this.isUninitialized
            || this.isStale;
    }

    @derived
    private get isUninitialized() {
        return !this.hasEverLoaded
            && !this.isLoading;
    }

    @reduced
    private isStale = reduce(false)
        .on(this._stale, () => true)
        .on(this._event.resolved, () => false)
        .on(this._event.rejected, () => false)
        .value;

    @reduced
    isLoading = reduce(!!this._initialPromise)
        .on(this._event.started, () => true)
        .on(this._event.resolved, () => false)
        .on(this._event.rejected, () => false)
        .value;

    @reduced
    hasEverLoaded = reduce(false)
        .on(this._event.resolved, () => true)
        .value;

    @reduced
    error = reduce(undefined as IException | undefined)
        .on(this._event.started, () => undefined)
        .on(this._event.rejected, (_, { error }) => error || { message: "Unknown Error" })
        .value;

    @reduced
    loaded = reduce(this._initialPromise ? voidPromise(this._initialPromise) : unresolvedPromiseLike())
        .on(this._event.started, (_, { promise }) => voidPromise(promise))
        .value;

    static combine(...events: IAsyncObservables<any, any>[]): ILoadState {
        return new CompositeLoadState(events.map(e => new LoadState(e)));
    }

    static never(): ILoadState {
        return new LoadState(asyncEvent());
    }
}

export class CompositeLoadState implements ILoadState {
    constructor(private _loadStates: ILoadState[]) { }

    @derived get isDubious() { return this._loadStates.some(s => s.isDubious); }
    @derived get isLoading() { return this._loadStates.some(s => s.isLoading); }
    @derived get hasEverLoaded() { return this._loadStates.every(s => s.hasEverLoaded); }
    @derived get error() { return this._loadStates.map(s => s.error).notUndefined()[0]; }
}

function unresolvedPromiseLike(): PromiseLike<void> {
    return new Promise<void>(() => { });
}

function voidPromise(promise: PromiseLike<any>) {
    return promise.then(() => { });
}

export class ObservableLoadState implements ILoadState {
    constructor(
        private _event: IStreamEventObservables<any, any>,
        private _initialObservable?: Observable<any>
    ) { }

    @derived
    get isDubious() {
        return this.isUninitialized;
    };

    @derived
    private get isUninitialized() {
        return !this.hasEverLoaded
            && !this.isLoading;
    }

    @reduced
    isLoading = reduce(!!this._initialObservable)
        .on(this._event.started, () => true)
        .on(this._event.completed, () => false)
        .on(this._event.errored, () => false)
        .value;

    @reduced
    hasEverLoaded = reduce(false)
        .on(this._event.completed, () => true)
        .value;

    @reduced
    error = reduce(undefined as IException | undefined)
        .on(this._event.started, () => undefined)
        .on(this._event.errored, (_, { error }) => error ?? { message: "Unknown Error" })
        .value;
}
