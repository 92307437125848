import { Sides, StartAndEnd } from "./Side";

export function scrollIntoView(element: Element, insets?: Partial<Sides<number>>, scrollBehavior: ScrollBehavior = 'smooth') {
    let scrollParent = findScrollParent(element);
    let elementRect = element.getBoundingClientRect();
    scrollRectIntoView(scrollParent, elementRect, insets, scrollBehavior);
}

export function scrollRectIntoView(scrollParent: HTMLElement, elementRect: DOMRectReadOnly, insets?: Partial<Sides<number>>, scrollBehavior: ScrollBehavior = 'smooth', onComplete?: () => void) {
    let scrollLeft = getSpanScrollAmount(scrollParent, true, elementRect.left, elementRect.right, { start: insets?.left ?? 0, end: insets?.right ?? 0 });
    let scrollTop = getSpanScrollAmount(scrollParent, false, elementRect.top, elementRect.bottom, { start: insets?.top ?? 0, end: insets?.bottom ?? 0 });

    if (scrollLeft.difference || scrollTop.difference) {
        if (onComplete && scrollBehavior == 'smooth')
            scrollParent.addEventListener('scrollend', onComplete, { once: true });
        scrollParent.scrollTo({ left: scrollLeft.target, top: scrollTop.target, behavior: scrollBehavior });
    }
}

export function scrollSpanIntoView(
    scrollParent: HTMLElement,
    horizontal: boolean,
    clientSpanStart: number,
    clientSpanEnd: number,
    insets?: StartAndEnd<number>,
    scrollBehavior: ScrollBehavior = 'smooth',
    onComplete?: () => void
) {
    let scroll = getSpanScrollAmount(scrollParent, horizontal, clientSpanStart, clientSpanEnd, { start: 0, end: 0, ...insets });

    if (scroll.difference) {
        if (onComplete && scrollBehavior == 'smooth')
            scrollParent.addEventListener('scrollend', onComplete, { once: true });
        let scrollSide = horizontal ? 'left' : 'top';
        scrollParent.scrollTo({ [scrollSide]: scroll.target, behavior: scrollBehavior });
    }
}

function getSpanScrollAmount(
    scrollParent: HTMLElement,
    horizontal: boolean,
    clientSpanStart: number,
    clientSpanEnd: number,
    insets: StartAndEnd<number>
) {
    let originalScrollAmount = horizontal ? scrollParent.scrollLeft : scrollParent.scrollTop;
    let targetScrollAmount = originalScrollAmount;

    let viewRect = visibleRect(scrollParent);
    let [viewStart, viewEnd] = horizontal
        ? [viewRect.left + insets.start, viewRect.right - insets.end]
        : [viewRect.top + insets.start, viewRect.bottom - insets.end];

    if (clientSpanEnd - clientSpanStart <= viewEnd - viewStart) { // Span fits inside view
        if (clientSpanStart < viewStart) {
            // Scroll back to start of span
            // From:   <-span-[->         view ]
            // To:    [<-span---> view ]
            targetScrollAmount -= viewStart - clientSpanStart;
        } else if (clientSpanEnd > viewEnd) {
            // Scroll forward to end of span with
            // From: [ view         <-]span->
            // To:          [ view  <--span->]
            targetScrollAmount += clientSpanEnd - viewEnd;
        }
    } else if (clientSpanEnd < viewRect.top) {
        // Scroll back end of view to end of span
        // From: <---span-->  [ view   ]
        // To:   <-[-span-->]
        //         [ view   ]
        targetScrollAmount -= viewEnd - clientSpanEnd;
    } else if (clientSpanStart > viewEnd) {
        // Scroll forward start of view to start of span
        // From: [ view   ]  <--span--->
        // To:              [<--span-]->
        //                  [ view   ]
        targetScrollAmount += clientSpanStart - viewStart;
    }

    return {
        target: targetScrollAmount,
        difference: targetScrollAmount - originalScrollAmount
    };
}

/** Returns the client rect of an element *inside* its scrollbars */
function visibleRect(element: Element): DOMRectReadOnly {
    let boundingRect = element.getBoundingClientRect();
    return new DOMRect(boundingRect.left, boundingRect.top, element.clientWidth, element.clientHeight);
}

export function findScrollParent(target: Element) {
    return findParent(target, isScrollElement);
}

export function findOverflowContainer(target: Element) {
    return findParent(target, isOverflowContainer);
}

function findParent(target: Element, predicate: (elementStyle: CSSStyleDeclaration) => boolean) {
    let current = target.parentElement;
    while (current) {
        if (predicate(getComputedStyle(current!)))
            return current!;
        current = current!.parentElement;
    }

    return document.documentElement;
}

function isOverflowContainer(style: CSSStyleDeclaration) {
    return [style.overflow, style.overflowX, style.overflowY].some(o => o != 'visible');
}

function isScrollElement(style: CSSStyleDeclaration) {
    return ['auto', 'scroll'].includes(style.overflowX!)
        || ['auto', 'scroll'].includes(style.overflowY!)
        || ['auto', 'scroll'].includes(style.overflow!);
}

export function isPositioned(style: CSSStyleDeclaration) {
    return style.position != 'static';
}

export function relativeDocumentPosition(a: Node, b: Node) {
    let position = a.compareDocumentPosition(b);
    return position & (Node.DOCUMENT_POSITION_FOLLOWING | Node.DOCUMENT_POSITION_CONTAINED_BY) ? -1
        : position & (Node.DOCUMENT_POSITION_PRECEDING | Node.DOCUMENT_POSITION_CONTAINS) ? 1
            : 0;
}
