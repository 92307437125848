import { LoginModel } from "./LoginModel";
import { EmbeddedLoginModel } from "./EmbeddedLoginModel";
import { state } from "event-reduce";

export class LoginPageModel {
    @state
    login = new LoginModel();

    @state
    embedded = new EmbeddedLoginModel();
}