import { Maybe } from "./Types";

export function clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
};

export function safeResult<T>(a: Maybe<T>, b: Maybe<T>, transform: (a: T, b: T) => T): T | undefined {
    return a == null || b == null
        ? undefined
        : transform(a, b);
}

export function safeAdd(a: Maybe<number>, b: Maybe<number>): number | undefined {
    return safeResult(a, b, (x, y) => x + y);
}

export function safeSubtract(a: Maybe<number>, b: Maybe<number>): number | undefined {
    return safeResult(a, b, (x, y) => x - y);
}