export class Uuid {
    static empty = '00000000-0000-0000-0000-000000000000';

    static create() {
        return this._template.replace(/[xy]/g, c => {
            let r = Math.random() * 16 | 0;
            let v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static isUuid(value: string) {
        return Uuid._isUuidPattern.test(value) ? value : null;
    }

    private static _template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    private static _isUuidPattern = new RegExp("^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$");
};