import { Center, Column } from "@zap/ui/lib/Box";
import { blackColor, roundedBorders } from "@zap/ui/lib/CommonStyles";
import { defaultFormWidth } from "@zap/ui/lib/Form";
import { ZapLogo } from "@zap/ui/lib/Icons/ZapLogo";
import { standardSpacing } from "@zap/ui/lib/Sizes";
import { Styled, animation, boxShadow, ease, style } from "@zap/ui/lib/styling";
import { IChildren, forwardRef } from "@zap/utils/lib/ReactHelpers";
import { Ref } from "react";
import "../../../../SharedSource/Web.Core/Scripts/extensions";

export interface ILoginContainerProps extends IChildren {
    headerImage: string;
    isCustomLogo: boolean;
}

export const LoginContainer = forwardRef(function LoginContainer({ children, headerImage, isCustomLogo }: ILoginContainerProps, ref: Ref<HTMLDivElement>) {
    return <Center fit>
        <Column>
            {isCustomLogo
                ? <Styled.img src={headerImage} styles={logo} />
                : <ZapLogo height={logoHeight} barbs="white" />
            }
            <Column spacing="none" positioned styles={containerStyles} ref={ref}>
                <Styled.div className="arrow arrow-right arrow-top" styles={bigArrow} />
                {children}
            </Column>
        </Column>
    </Center>
});

let containerStyles = style('container', {
    ...roundedBorders,
    boxShadow: boxShadow(standardSpacing, standardSpacing, 0, blackColor),
    background: 'white',
    width: defaultFormWidth,
    padding: standardSpacing, // Using explicit padding instead of sidePadding because the half-bleed on the inputs looks off on the login page
    $: {
        [`@media(max-width: ${defaultFormWidth}px)`]: {
            width: '100vw'
        }
    },
    margin: 'auto'
});

const logoHeight = 96;

let logo = style('logo', {
    height: logoHeight
});

let bigArrow = style('bigArrow', {
    '--arrow-thickness': '3vw',
    width: '30vw',
    top: 'calc(50% - var(--arrow-thickness) / 2)',
    position: 'absolute',
    right: 'calc(100% + 10vw)',
    animation: animation({
        from: { transform: { translateX: '-100%' } },
        to: { transform: { translateX: '0' } }
    }, '2s', ease.enter, '0.5s', 1, 'normal', 'both'),
    $: {
        [`@media(max-width: 950px)`]: {
            display: 'none'
        }
    }
});