import { MaybeArray } from "@zap/utils/lib/Types";
import { Globals, Property } from "csstype";
import { CSSProperties, Styles, TLength, defaultUnit } from "stylemap";
export * from "stylemap";
export * from "./StyledComponent";

export const defaultPx = defaultUnit('px');

export const defaultMs = defaultUnit('ms');

export function important<T>(value: T): T {
    return defaultPx(value) + ' !important' as T;
}

export function border(width: Property.BorderWidth<TLength>, style: Property.BorderStyle, color: Styles['color']): string {
    return `${defaultPx(width)} ${style} ${color}`;
}

export function boxShadow(offsetX: TLength, offsetY: TLength, color: CSSProperties['color']): string;
export function boxShadow(offsetX: TLength, offsetY: TLength, blurRadius: TLength, color: CSSProperties['color']): string;
export function boxShadow(offsetX: TLength, offsetY: TLength, blurRadius: TLength, spreadRadius: TLength, color: CSSProperties['color']): string;
export function boxShadow(offsetX: TLength, offsetY: TLength, blurRadius: TLength | CSSProperties['color'], spreadRadius?: TLength | CSSProperties['color'], color?: CSSProperties['color']): string {
    let parts = [] as string[];
    parts.push(defaultPx(offsetX));
    parts.push(defaultPx(offsetY));
    parts.push(defaultPx(blurRadius));
    if (spreadRadius != null)
        parts.push(defaultPx(spreadRadius));
    if (color != null)
        parts.push(color.toString());
    return parts.join(' ');
}

export function transition(property: string | 'all' | 'none', duration: number | string, timing?: Property.TransitionTimingFunction, delay?: number | string): string;
export function transition(property: (string | 'all' | 'none')[], duration: number | string, timing?: Property.TransitionTimingFunction, delay?: number | string): string[];
export function transition(property: MaybeArray<string | 'all' | 'none'>, duration: number | string, timing: Property.TransitionTimingFunction = ease.default, delay: number | string = 0): string | string[] {
    let single = (p: string) => `${p} ${defaultMs(duration)} ${defaultMs(delay)} ${timing}`;
    return Array.isArray(property)
        ? property.map(single)
        : single(property);
}

export function hsl(hue: number, saturation: number, lightness: number): string {
    return `hsl(${hue} ${saturation * 100}% ${lightness * 100}%)`;
}

export function hsla(hue: number, saturation: number, lightness: number, alpha: number): string {
    return `hsl(${hue} ${saturation * 100}% ${lightness * 100}% / ${alpha})`;
}

export function lighten(color: NonNullable<Styles['color']>, k: number = 1) {
    return relativeColorsSupported
        ? `hsl(from ${color} h s calc(l / pow(.7, ${k})) / alpha)`
        : undefined; // Property will just be ignored
}

export function darken(color: NonNullable<Styles['color']>, k: number = 1) {
    return relativeColorsSupported
        ? `hsl(from ${color} h s calc(pow(.7, ${k}) * l) / alpha)`
        : undefined; // Property will just be ignored
}

export function fade(color: NonNullable<Styles['color']>, alpha: number) {
    return relativeColorsSupported
        ? `hsl(from ${color} h s l / ${alpha})`
        : undefined; // Property will just be ignored
}

const relativeColorsSupported = CSS.supports('color', 'hsl(from white h s l)');

export type FlexDirection = Globals
    | 'row'
    | 'column'
    | 'row-reversed'
    | 'column-reversed';

export let ease = {
    default: 'cubic-bezier(.4, 0, .2, 1)',
    enter: 'cubic-bezier(0, 0, .2, 1)',
    exit: 'cubic-bezier(.4, 0, 1, 1)'
};

/**
 * Escapes a string to be used as a custom identifier in CSS.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/custom-ident
 **/
export function customIdent(key: string) {
    return key
        .replace(/[^-_a-zA-Z0-9]/g, c => '\\' + c.charCodeAt(0).toString(16) + ' ')
        .replace(/^-?[0-9]/, '_$&'); // Can't start with a number or a hyphen then a number
}