/// <reference path="../../../../SharedSource/Web.Core/Client/typings/ZapVariables.d.ts" />
/// <reference path="../typings/UserController.d.ts" />
import { Column, Row } from "@zap/ui/lib/Box";
import { Button } from "@zap/ui/lib/Button";
import { Form } from "@zap/ui/lib/Form";
import { HelperText } from "@zap/ui/lib/HelperText";
import { Icon } from "@zap/ui/lib/Icons/Icon";
import { usePopupAnchor } from "@zap/ui/lib/PopupAnchor";
import { useScrollTo } from "@zap/ui/lib/Scrolling";
import { Side } from "@zap/ui/lib/Side";
import { Styled } from "@zap/ui/lib/StyledComponent";
import { Switch } from "@zap/ui/lib/Switch";
import { TextBox } from "@zap/ui/lib/TextBox";
import { reactive } from "event-reduce-react";
import { style } from "stylemap";
import "../../../../SharedSource/Web.Core/Client/Text";
import { Tooltip } from "../../../../SharedSource/Web.Core/Client/Tooltip";
import "../../../../SharedSource/Web.Core/Scripts/Tooltips";
import "../../../../SharedSource/Web.Core/Scripts/extensions";
import { antiForgeryHeaders } from "../AntiForgery";
import { ILoginPageProps } from "./LoginBoot";
import { LoginContainer } from "./LoginContainer";
import { LoginModel } from "./LoginModel";

export interface ILoginFormProps extends ILoginPageProps {
    model: LoginModel;
}

export const LoginForm = reactive(function LoginForm(props: ILoginFormProps) {
    let model = props.model;
    let events = model.events;

    let [containerRef, containerAnchor] = usePopupAnchor<HTMLDivElement>();
    let scroll = useScrollTo<HTMLButtonElement>(model.loggingIn.isLoading);

    let wideEnoughToShowTooltipsOnTheSide = window.innerWidth >= 1024;
    let tooltipAnchor = wideEnoughToShowTooltipsOnTheSide ? containerAnchor : undefined;
    let tooltipSide: Side = wideEnoughToShowTooltipsOnTheSide ? 'right' : 'bottom';

    return <LoginContainer headerImage={props.headerImage} isCustomLogo={props.isCustomLogo} ref={containerRef}>
        <Form onSubmit={() => login()}>
            <Column>
                <Styled.h1 styles={loginHeader}>{PageText.Login_DomainLogin}</Styled.h1>
                <Tooltip definition={Zap.BI.Tooltips.Login.LoginUsername} position={tooltipSide} anchor={tooltipAnchor}>
                    <TextBox
                        label={PageText.Login_UserName}
                        name="username"
                        autoCapitalize="none"
                        value={model.username}
                        onChange={e => events.usernameChanged(e.target.value)}
                        isValid={() => model.usernameValidity}
                        errorText={() => model.usernameError}
                        showEmptyHelper
                    />
                </Tooltip>
                <Tooltip definition={Zap.BI.Tooltips.Login.LoginPassword} position={tooltipSide} anchor={tooltipAnchor}>
                    <TextBox
                        label={PageText.Login_Password}
                        name="password"
                        type="password"
                        value={model.password}
                        onChange={e => events.passwordChanged(e.target.value)}
                        isValid={() => model.passwordValidity}
                        errorText={() => model.passwordError}
                        showEmptyHelper
                    />
                </Tooltip>
                <Tooltip definition={Zap.BI.Tooltips.Login.LoginRememberMe} position={tooltipSide} anchor={tooltipAnchor}>
                    <Switch label={PageText.Login_RememberMe} checked={model.rememberMe} onChange={e => events.rememberMeChanged({ enabled: e.target.checked })} />
                </Tooltip>

                <Column alignItems="center">
                    <HelperText errorText={model.loginError} />
                    <Button type="submit" ref={scroll} contained isLoading={model.loggingIn.isLoading} disabled={model.hasChallenged}>{PageText.Login_Button}</Button>
                </Column>

                <Column alignItems="center">
                    <Styled.h1 styles={loginHeader}>or</Styled.h1>
                    <Tooltip definition={Zap.BI.Tooltips.Login.LoginWindowsChallenge} position={tooltipSide} anchor={tooltipAnchor}>
                        <Button grey contained onClick={() => challenge()} isLoading={model.hasChallenged} disabled={model.loggingIn.isLoading} styles={iconButton}>
                            <Row center halfSpacing>
                                <Icon name="windows" light disabled={model.hasChallenged || model.loggingIn.isLoading} />
                                <span>{PageText.Login_WindowsChallenge}</span>
                            </Row>
                        </Button>
                    </Tooltip>
                </Column>
            </Column>
        </Form>
    </LoginContainer>

    function login() {
        if (model.username && model.password) {
            let authRequest: server.AuthenticationRequest = {
                Username: model.username,
                Password: model.password,
                IsPersistent: model.rememberMe
            };

            let loggedIn = fetch(props.authUrl,
                {
                    method: 'POST',
                    headers: [['content-type', 'application/json'], ['Accept', 'application/json'], ...Object.entries(antiForgeryHeaders())],
                    body: JSON.stringify(authRequest)
                })
                .then(response => {
                    if (!response.ok)
                        return response.json().then<void>(e => Promise.reject(e));
                })
                .then(() => {
                    if (props.isPopup) {
                        if (window.opener)
                            window.opener.postMessage({ loginComplete: true }, location.origin);
                        window.close();
                    } // Fall through to redirect in case this isn't actually a popup

                    location.href = props.returnUrl;
                    return new Promise<void>(() => { }); // Don't let promise end, so button still appears loading while navigating
                });

            events.authenticated(loggedIn);
        } else {
            events.authenticated(Promise.reject({}));
        }
    }

    function challenge() {
        events.challenged();
        location.assign(props.challengeUrl);
    }
});

let iconButton = style('iconButton', {
    height: 40
});

let loginHeader = style('loginHeader', {
    margin: 0,
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: 1.2
});