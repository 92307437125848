import { variable } from "./styling";

export const orange = {
    900: variable('color', 'orange900', '#b54c1b'),
    800: variable('color', 'orange800', '#c95620'),
    700: variable('color', 'orange700', '#d95c1e'),
    600: variable('color', 'orange600', '#e36927'),
    500: variable('color', 'orange500', '#ed6f26'),
    400: variable('color', 'orange400', '#f07e37'),
    300: variable('color', 'orange300', '#f79659'),
    200: variable('color', 'orange200', '#fab589'),
    100: variable('color', 'orange100', '#fcd2b8'),
    50: variable('color', 'orange50', '#faece3')
};
export const blue = {
    900: variable('color', 'blue900', '#2c545c'),
    800: variable('color', 'blue800', '#3e7987'),
    700: variable('color', 'blue700', '#468b9e'),
    600: variable('color', 'blue600', '#4f9fb8'),
    500: variable('color', 'blue500', '#56b0cc'),
    400: variable('color', 'blue400', '#5fbad4'),
    300: variable('color', 'blue300', '#74c3db'),
    200: variable('color', 'blue200', '#99d7e8'),
    100: variable('color', 'blue100', '#bde6f2'),
    50: variable('color', 'blue50', '#e6f6fa'),
}
export const yellow = {
    900: variable('color', 'yellow900', '#d97e00'),
    800: variable('color', 'yellow800', '#de8909'),
    700: variable('color', 'yellow700', '#e69512'),
    600: variable('color', 'yellow600', '#f29e0c'),
    500: variable('color', 'yellow500', '#faa507'),
    400: variable('color', 'yellow400', '#f7b023'),
    300: variable('color', 'yellow300', '#fac04d'),
    200: variable('color', 'yellow200', '#ffd480'),
    100: variable('color', 'yellow100', '#ffe4b3'),
    50: variable('color', 'yellow50', '#fff5e0'),
};
export const purple = {
    900: variable('color', 'purple900', '#363e78'),
    800: variable('color', 'purple800', '#454c8a'),
    700: variable('color', 'purple700', '#535b99'),
    600: variable('color', 'purple600', '#5e68a6'),
    500: variable('color', 'purple500', '#6875ba'),
    400: variable('color', 'purple400', '#7b88c7'),
    300: variable('color', 'purple300', '#8a98d4'),
    200: variable('color', 'purple200', '#a4b5eb'),
    100: variable('color', 'purple100', '#c7d1f2'),
    50: variable('color', 'purple50', '#ebeefa'),
};
export const green = {
    900: variable('color', 'green900', '#287030'),
    800: variable('color', 'green800', '#4c9443'),
    700: variable('color', 'green700', '#5da84c'),
    600: variable('color', 'green600', '#70bd57'),
    500: variable('color', 'green500', '#7fcf5f'),
    400: variable('color', 'green400', '#93d678'),
    300: variable('color', 'green300', '#a6de90'),
    200: variable('color', 'green200', '#b0e8a9'),
    100: variable('color', 'green100', '#dbf2d0'),
    50: variable('color', 'green50', '#effaeb'),
};
export const red = {
    900: variable('color', 'red900', '#b91f09'),
    800: variable('color', 'red800', '#c82b19'),
    700: variable('color', 'red700', '#d53220'),
    600: variable('color', 'red600', '#e73d25'),
    500: variable('color', 'red500', '#ef4a2a'),
    400: variable('color', 'red400', '#f15544'),
    300: variable('color', 'red300', '#e9746a'),
    200: variable('color', 'red200', '#f29a94'),
    100: variable('color', 'red100', '#ffcdcf'),
    50: variable('color', 'red50', '#ffebed'),
};

export const colorPalettes = { orange, blue, yellow, purple, green, red };

export type PaletteName = keyof typeof colorPalettes;