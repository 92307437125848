import { combineRefs, forwardRef, useEventListener } from "@zap/utils/lib/ReactHelpers";
import { ReactElement, Ref, useEffect, useRef } from "react";
import { useIsMouseDownOnElement, useWindowMouseMove } from "./Mouse";
import { Side, Sides } from "./Side";
import { standardSpacing } from "./Sizes";
import { CSSProperties, StyleCollection, Styled, classes, defaultPx, important, style, variable } from "./styling";

export interface IResizableOptions {
    disabled?: boolean;
    handle: Partial<Sides<number>>;
    onResize?(newSize: { width: number; height: number; }): void;
    onResizeComplete?(newSize: { width: number; height: number; }): void;
    onReset?(): void;
    minWidth?: number;
    minHeight?: number;
}

interface IResizeOperation {
    width: number;
    height: number;
    left: number;
    mouseX: number;
    mouseY: number;
    xHandle: 'left' | 'right' | undefined;
    yHandle: 'top' | 'bottom' | undefined;
    cursor: string;
}

export function useResizable(options: IResizableOptions) {
    let elementRef = useRef<HTMLElement>(null);
    let mousedownRef = useEventListener('mousedown', onMouseDown);
    let clickRef = useEventListener('click', onClick);
    let doubleClickRef = useEventListener('dblclick', onDoubleClick);
    let resize = useRef(null as IResizeOperation | null);
    let resizeInProgress = useRef(false);
    let elementStyle = useRef<CSSStyleDeclaration>(null!); // Element style is live, so only need to get it once

    useEffect(() => {
        if (elementRef.current)
            elementStyle.current ??= getComputedStyle(elementRef.current, '::after'); // ::after is common pattern from resize handle, but will inherit parent element as well
    }, []);

    return combineRefs(elementRef, mousedownRef, clickRef, doubleClickRef);

    function onMouseDown(event: MouseEvent) {
        if (!options.disabled) {
            let target = targetHandle(event);
            if (target.xHandle || target.yHandle) {
                event.stopImmediatePropagation();
                document.addEventListener('mousemove', onDrag, true);
                document.addEventListener('mouseup', onDragEnd, true);
                resize.current = {
                    width: target.rect.width,
                    height: target.rect.height,
                    left: target.rect.left,
                    mouseX: event.clientX,
                    mouseY: event.clientY,
                    xHandle: target.xHandle,
                    yHandle: target.yHandle,
                    cursor: elementStyle.current!.cursor
                };
            }
        }
    }

    function onClick(event: MouseEvent) {
        if (!options.disabled) {
            let target = targetHandle(event);
            if (target.xHandle || target.yHandle)
                event.stopImmediatePropagation();
        }
    }

    function onDoubleClick(event: MouseEvent) {
        if (!options.disabled) {
            let target = targetHandle(event);
            if (target.xHandle || target.yHandle) {
                event.stopImmediatePropagation();
                options.onReset?.();
            }
        }
    }

    function onDrag(event: MouseEvent) {
        event.preventDefault();
        if (!resizeInProgress.current) {
            resizeInProgress.current = true;
            document.body.classList.add(classes(isResizeInProgress));
            document.body.style.setProperty(resizeCursor.var[0], resize.current!.cursor);
            elementRef.current!.classList.add(classes(isBeingResized));
        }
        options.onResize?.(newSize(event));
    }

    function onDragEnd(event: MouseEvent) {
        resizeInProgress.current = false;
        document.removeEventListener('mousemove', onDrag, true);
        document.removeEventListener('mouseup', onDragEnd, true);
        document.body.classList.remove(classes(isResizeInProgress));
        document.body.style.setProperty(resizeCursor.var[0], null);
        elementRef.current!.classList.remove(classes(isBeingResized));
        options.onResizeComplete?.(newSize(event));
    }

    function newSize(event: MouseEvent): { width: number; height: number; } {
        let initial = resize.current!;
        let { rect } = targetHandle(event);

        let xDelta = initial.xHandle == 'left' ? initial.mouseX - event.clientX
            : initial.xHandle == 'right' ? event.clientX - initial.mouseX
                : 0;
        let yDelta = initial.yHandle == 'top' ? initial.mouseY - event.clientY
            : initial.yHandle == 'bottom' ? event.clientY - initial.mouseY
                : 0;

        return {
            width: Math.max(options.minWidth ?? 0, initial.width + xDelta + (initial.left - rect.left)), // initial.left - rect.left is to account for centered tables
            height: Math.max(options.minHeight ?? 0, initial.height + yDelta)
        };
    }

    function targetHandle(event: MouseEvent) {
        let rect = elementRef.current!.getBoundingClientRect();
        // ceil & floor, otherwise you won't can't resize on the edge pixel when the rect isn't pixel-aligned
        return {
            rect,
            xHandle: event.clientX <= Math.ceil(rect.left) + (options.handle.left ?? 0) ? 'left'
                : event.clientX >= Math.floor(rect.right) - (options.handle.right ?? 0) ? 'right'
                    : undefined,
            yHandle: event.clientY <= Math.ceil(rect.top) + (options.handle.top ?? 0) ? 'top'
                : event.clientY >= Math.floor(rect.bottom) - (options.handle.bottom ?? 0) ? 'bottom'
                    : undefined
        } as const;
    }
}

export const isBeingResized = style('is-beingResized', {});

let resizeCursor = variable('cursor', 'resize-cursor');

const isResizeInProgress = style('is-resizingInProgress', {
    $: {
        '*': {
            cursor: important(resizeCursor.or('nwse-resize'))
        }
    }
});

/** Selector for something somewhere being resized */
export const currentlyResizing = `body.${isResizeInProgress}`;
/** Selector for nothing currently being resized */
export const notCurrentlyResizing = `body:not(.${isResizeInProgress})`;


export function useWindowResize(onResize: (e: UIEvent) => void) {
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);
}

interface ResizableProps {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
    onResize?: (width: number, height: number) => void;
    onReset?: () => void;
    children: ReactElement;
    styles?: StyleCollection;
    inline?: CSSProperties;
}

const resizeTriggerThreshold = standardSpacing;

export const Resizable = forwardRef(function Resizable({ top, right, bottom, left, onResize, onReset, children, styles, inline }: ResizableProps, ref: Ref<HTMLDivElement>) {
    let divRef = useRef<HTMLDivElement>(null);

    return <Styled.div styles={[resizable, styles]} inline={inline} ref={combineRefs(divRef, ref)}>
        {top && <ResizeHandle side='top' onResize={resize} onReset={reset} />}
        {right && <ResizeHandle side='right' onResize={resize} onReset={reset} />}
        {bottom && <ResizeHandle side='bottom' onResize={resize} onReset={reset} />}
        {left && <ResizeHandle side='left' onResize={resize} onReset={reset} />}
        {children}
    </Styled.div>

    function resize(side: Side, clientX: number, clientY: number) {
        let size = Math.round(getNewSize(side, clientX, clientY));

        if (isTopOrBottom(side))
            divRef.current!.style.height = size === null ? '' : defaultPx(size);
        else
            divRef.current!.style.width = size === null ? '' : defaultPx(size);

        let updatedRect = divRef.current?.getBoundingClientRect()!;
        onResize?.(updatedRect.width, updatedRect.height);
    }

    function getNewSize(side: Side, clientX: number, clientY: number) {
        let rect = divRef.current?.getBoundingClientRect()!;

        if (side === 'top')
            return rect.height + rect.top - clientY;
        else if (side === 'right')
            return rect.width + clientX - rect.right;
        else if (side === 'bottom')
            return rect.height + clientY - rect.bottom;
        else
            return rect.width + rect.left - clientX;
    }

    function reset(side: Side) {
        if (isTopOrBottom(side))
            divRef.current!.style.height = '';
        else
            divRef.current!.style.width = '';

        onReset?.();
    }
}) as <T>(props: ResizableProps & { ref?: Ref<T> }) => ReactElement;

interface ResizeHandleProps {
    onResize: (side: Side, clientX: number, clientY: number) => void;
    onReset: (side: Side) => void;
    side: Side;
}

function ResizeHandle({ side, onResize, onReset }: ResizeHandleProps) {
    let [handleRef, isMouseDown] = useIsMouseDownOnElement<HTMLDivElement>();
    useWindowMouseMove(windowMouseMove);
    useWindowResize(() => onReset(side));

    let cursorStyle = isTopOrBottom(side)
        ? nsResize
        : ewResize;

    return <Styled.div styles={[resizeArea, cursorStyle]} inline={getPosition()} onDoubleClick={onDoubleClick} ref={handleRef} />

    function windowMouseMove(e: MouseEvent) {
        if (!isMouseDown) {
            document.body.classList.remove(ewResize.toString());
            document.body.classList.remove(nsResize.toString());
        } else {
            document.body.classList.add(cursorStyle.toString());
            resize(e.clientX, e.clientY);
        }
    }

    function getPosition() {
        if (side === 'top')
            return { width: '100%', height: resizeTriggerThreshold * 2, top: -resizeTriggerThreshold };
        else if (side === 'right')
            return { width: resizeTriggerThreshold * 2, height: '100%', right: -resizeTriggerThreshold, top: 0 };
        else if (side === 'bottom')
            return { width: '100%', height: resizeTriggerThreshold * 2, bottom: -resizeTriggerThreshold };
        else
            return { width: resizeTriggerThreshold * 2, height: '100%', left: -resizeTriggerThreshold, top: 0 }
    }

    function resize(clientX: number, clientY: number) {
        onResize(side, clientX, clientY);
    }

    function onDoubleClick() {
        onReset(side);
    }
}

function isTopOrBottom(side: Side) {
    return side === 'top' || side === 'bottom';
}

let nsResize = style('nsresize', {
    cursor: 'ns-resize',
    userSelect: 'none',
    $: {
        '*': {
            cursor: 'ns-resize',
            userSelect: 'none',
        }
    }
});

let ewResize = style('ewresize', {
    cursor: 'ew-resize',
    userSelect: 'none',
    $: {
        '*': {
            cursor: 'ew-resize',
            userSelect: 'none',
        }
    }
});

let resizable = style('resizable', {
    position: 'relative'
});

export let resizeArea = style('resize-area', { // exported for debugging purposes
    position: 'absolute'
});
