import { forwardRef, IChildren } from "@zap/utils/lib/ReactHelpers";
import { HTMLProps, PropsWithoutRef, ReactNode, Ref } from "react";
import { halfBleed, noSpacing, Row } from "./Box";
import { buttonIcon, buttonStyle, buttonWrapper, containedButton, disabledButton, flatButton, loadingButton, outlinedButton } from "./ButtonStyles";
import { Clickable, ClickEvent } from "./Clickable";
import { duration } from "./CommonStyles";
import { useDisabled } from "./Disabling";
import { useFormLabelSpacing, useFormButtonClick as useLegacyFormButtonClick } from "./Form";
import { Icon, iconHoverContainer, IconName, IconSizes } from "./Icons/Icon";
import { Loading, useLoadingDelay } from "./Loading";
import { useFormButtonClick } from "./NewForm";
import { standardSpacing } from "./Sizes";
import { style, StyleCollection, Styled, transition } from "./styling";

type HTMLButtonProps = PropsWithoutRef<HTMLProps<HTMLButtonElement>>

export interface IButtonProps {
    icon?: IconName;
    onClick?: (e: ClickEvent) => void;
    hideRipple?: boolean;
    outlined?: boolean;
    contained?: boolean;
    disabled?: boolean;
    selected?: boolean;
    dangerous?: boolean;
    grey?: boolean;
    white?: boolean;
    styles?: StyleCollection;
    isLoading?: boolean;
    children?: ReactNode;
    minWidth?: number;
}

export const Button = forwardRef(function Button(props: IButtonProps & HTMLButtonProps, ref: Ref<HTMLButtonElement>) {
    let { dangerous, grey, white, styles, onClick, isLoading, hideRipple, disabled, outlined, contained, icon, type, minWidth, ...buttonProps } = props;
    let isDisabled = useDisabled(disabled || isLoading);

    let buttonClass = contained || isLoading ? containedButton
        : outlined ? outlinedButton
            : flatButton;

    let formClick = useLegacyFormButtonClick(type, useFormButtonClick(type, onClick));

    let showLoading = useLoadingDelay(isLoading);

    return <Clickable
        color={dangerous ? 'dangerous' : grey ? 'darkGrey' : white ? 'white' : 'default'}
        invert={contained}
        hideRipple={hideRipple}
        disabled={isDisabled}
        onClick={formClick}
        styles={[buttonWrapper, buttonClass, isDisabled && disabledButton, !(contained || outlined) && halfBleed, iconHoverContainer]}
    >
        {({ getFocusProps }) =>
            <Styled.button
                ref={ref}
                type="button"
                role="button"
                styles={[buttonStyle, textButton, isLoading && loadingButton, styles]} /* disable with styling so mouse events are still triggered, Clickable prevents onClick */
                inline={{ minWidth }}
                tabIndex={isDisabled ? -1 : undefined} /* prevent keyboard navigation if disabled */
                aria-disabled={isDisabled}
                {...getFocusProps(buttonProps)}>
                <Row spacing="half" justifyContent="center" alignItems="center" width="100%">
                    {icon && <Icon name={icon} size="tiny" styles={buttonIcon} useCurrentColor />}
                    {props.children && <div>
                        {props.children}
                    </div>}
                    <Row styles={[spinner, showLoading ? loadingSpinner : noSpacing]}>
                        {showLoading && <Loading size="tiny" />}
                    </Row>
                </Row>
            </Styled.button>
        }
    </Clickable>;
});

let textButton = style('button-textButton', {
    minWidth: 64
});

export let spinner = style('button-spinner', {
    marginTop: -1,
    width: 0,
    transform: { scale: 0 },
    transition: transition('all', duration.medium)
});

let loadingSpinner = style('button-spinner-loading', {
    width: IconSizes.tiny,
    transform: { scale: 1 }
});

export interface IButtonListProps extends IChildren {
    align?: 'start' | 'end';
}

export const ButtonList = forwardRef(function ButtonList(props: IButtonListProps, ref: Ref<any>) {
    let formSpacing = useFormLabelSpacing();

    return <Row
        ref={ref}
        justifyContent={props.align === 'start' ? 'flex-start' : 'flex-end'}
        spacing="none"
        sidePadding
        styles={[buttonListSpacing, formSpacing]}>
        {props.children}
    </Row>
});

let buttonListSpacing = style('buttonList-spacing', {
    $: {
        [`> .${buttonWrapper} + .${buttonWrapper}`]: {
            marginLeft: standardSpacing
        },
        [`> * + .${flatButton}.${buttonWrapper}`]: {
            marginLeft: standardSpacing / 2
        },
        [`> .${flatButton}.${buttonWrapper} + *`]: {
            marginLeft: standardSpacing / 2
        }
    }
});
