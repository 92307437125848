import { darkBarbsColor, lightBarbsColor } from "../CommonStyles";
import { Styles } from "../styling";

interface IZapLogoProps {
    light?: boolean;
    barbs?: Styles['color'];
    height?: number;
}

export function ZapLogo({ light, barbs = light ? lightBarbsColor : darkBarbsColor, height = 32 }: IZapLogoProps) {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="39.589 25 570.822 400" height={height}>
        <path fill={light ? '#fff' : '#000'} d="M542.902 167.829c-27.062 0-46.77 15.443-57.211 40.446h-1.03v-36.769h-31.474v221.937h32.21v-86.922h1.028c10.296 23.385 29.416 36.329 55.889 36.329 40.152 0 68.097-33.975 68.097-87.805 0-53.83-26.769-87.216-67.508-87.216zM532.607 315.64c-28.973 0-47.504-23.09-47.504-60.301 0-38.092 18.09-60.595 47.504-60.595 27.357 0 44.859 22.944 44.859 60.301 0 38.387-16.767 60.595-44.859 60.595zm-101.945.928c-2.112.172-4.25.172-6.833-.026-10.845-.835-15.048-6.046-15.466-18.809-.043-.608-.064-3.207-.064-3.899v-64.528c0-41.032-23.532-61.478-62.36-61.478-36.327 0-62.212 19.709-72.067 55.89l30.027 5.774s.792-2.923 1.502-5.515c5.357-19.555 19.179-29.125 37.369-29.974a58.209 58.209 0 015.834.01c18.09.933 28.516 10.691 28.516 25.587 0 12.206-6.765 18.383-27.65 21.619l-23.825 3.53c-18.974 2.795-32.358 7.648-41.476 14.855-11.472 9.118-16.62 21.474-16.62 36.916 0 28.692 19.612 45.99 50.144 46.319.591.007 1.181.007 1.772 0 28.346-.309 46.872-15.386 56.479-37.347h.714l.073 1.381c.869 22.305 13.632 33.786 36.734 34.444 1.38.041 2.784.041 4.197.005 6.76-.184 13.746-1.242 16.964-2.593v-22.527s-2.059.211-3.964.366zm-53.248-54.757c0 33.973-17.942 57.359-47.064 57.359-18.238 0-29.709-9.266-29.709-26.768 0-16.914 9.265-25.151 31.473-29.122l21.18-3.823c11.767-2.206 19.561-4.56 24.121-8.531v10.885zM251.208 110.975v28.052l-85.974 85.974-19.832-19.839 66.135-66.135H39.589v-28.052zM39.589 339.025v-28.052l85.974-85.974 19.832 19.839-66.135 66.135h171.948v28.052z" />
        <path fill={barbs.toString()} d="M193.296 92.736l-47.897-47.897L165.238 25l67.736 67.736zM57.823 357.264L125.559 425l19.839-19.839-47.897-47.897z" />
    </svg>
}