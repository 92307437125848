import { Key } from 'ts-key-enum';

type Char
    = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y' | 'z'
    | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z'
    | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '-' | '=' | '!' | '@' | '#' | '$' | '%' | '^' | '&' | '*' | '(' | ')' | '_' | '+' | '[' | ']'
    | '{' | '}' | '\\' | '|' | ';' | ':' | "'" | '"' | '`' | '~' | ',' | '.' | '<' | '>' | '/' | '?' | ' ';

export type SingleKey = keyof typeof Key | Char;
type ModifiedKey = `ctrl+${SingleKey}` | `alt+${SingleKey}` | `shift+${SingleKey}`;

type IKeyboardEvents = {
    [key in SingleKey | ModifiedKey | 'default']?: (e: React.KeyboardEvent) => boolean | void;
}

/**
 * Map of key to handler.
 * Use e.g. 'ctrl+c' or 'alt+c' to only handle modified key presses. 
 * Unmodified key handlers will be called if no modifier is pressed, or no modified key handler is specified.
 * 'default' handler can be specified as a callback if no other handler matches.
 */
export function keymap<Element extends HTMLElement = HTMLElement>(events: IKeyboardEvents) {
    return function onKeyDown(e: React.KeyboardEvent<Element>) {
        let singleKey = e.key as SingleKey;
        let modifiedKey = e.ctrlKey ? `ctrl+${singleKey}` as const
            : e.altKey ? `alt+${singleKey}` as const
                : e.shiftKey ? `shift+${singleKey}` as const
                    : undefined;

        let handler = (modifiedKey && events[modifiedKey])
            ?? events[singleKey]
            ?? events.default;

        if (handler && handler(e) !== false) {
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
        }
    }
}

export function isSingleCharacter(key: string): key is Char {
    return key.length == 1;
}
