/** Override these properties with localized versions */
export const LocalText = {
    Columns: "Columns",
    Filter: "Filter",
    ClearFilters: "Clear filters",
    AllDataFilteredOut: "No items match the current filters",
    All: "All",
    Minimum: "Minimum",
    Maximum: "Maximum",
    None: "None",
    AnyDate: "Any date",
    Today: "Today",
    From: "From",
    To: "To",
    MultiSelect: "Multi-select",
    HelpLink: "More info"
};
