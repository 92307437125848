import "./ArrayExtensions";
import { ArrayPredicate } from "./ArrayExtensions";
import { clamp } from "./Math";
import { MaybeReadonlyArray } from "./Types";

export const emptyArray = Object.freeze([] as any[]);

export type ItemIdentifier<T, TThis> = ArrayPredicate<T, TThis> | T | number;

export function insert<T, TThis = void>(array: readonly T[], items: MaybeReadonlyArray<T>, itemToInsertBefore: ItemIdentifier<T, TThis>, thisArg?: TThis) {
    return moveBefore(array, items, itemToInsertBefore, thisArg);
}

export function matchOrder<T, Key>(source: readonly T[], order: readonly Key[], selectKey: (item: T) => Key) {
    if (order.length != source.length)
        throw new Error("Ordered keys array length doesn't match source array length");
    return order.map(key => source.findOrThrow(item => selectKey(item) == key));
}

export function move<T, TThis = void>(array: readonly T[], itemsToMove: ItemIdentifier<T, TThis>, delta: number, thisArg?: TThis) {
    return moveTo(array, itemsToMove, itemsToMove, delta, thisArg);
}

export function moveBefore<T, TThis = void>(
    array: readonly T[],
    itemsToMove: ItemIdentifier<T, TThis> | readonly T[],
    itemToMoveNextTo: ItemIdentifier<T, TThis>,
    thisArg?: TThis
) {
    return moveTo(array, itemsToMove, itemToMoveNextTo, 0, thisArg);
}

export function moveAfter<T, TThis = void>(
    array: readonly T[],
    itemsToMove: ItemIdentifier<T, TThis> | T[],
    itemToMoveNextTo: ItemIdentifier<T, TThis>,
    thisArg?: TThis
) {
    return moveTo(array, itemsToMove, itemToMoveNextTo, 1, thisArg);
}

export function moveTo<T, TThis = void>(
    array: readonly T[],
    itemsToMove: ItemIdentifier<T, TThis> | readonly T[],
    itemToMoveNextTo: ItemIdentifier<T, TThis>,
    deltaFromTarget: number,
    thisArg?: TThis
) {
    let movingItems = Array.isArray(itemsToMove) ? itemsToMove
        : typeof itemsToMove == 'function' ? array.filter(itemsToMove as ArrayPredicate<T, TThis>, thisArg)
            : typeof itemsToMove == 'number' ? [array[itemsToMove]]
                : [itemsToMove];
    let targetItem = typeof itemToMoveNextTo == 'function' ? array.findOrThrow(itemToMoveNextTo as ArrayPredicate<T, TThis>, thisArg)
        : typeof itemToMoveNextTo == 'number' ? array[itemToMoveNextTo]
            : itemToMoveNextTo;

    if (movingItems.includes(targetItem) && deltaFromTarget > 0)
        deltaFromTarget++;

    let targetItemIndex = array.indexOf(targetItem);
    let targetIndex = clamp(targetItemIndex >= 0 ? targetItemIndex + deltaFromTarget : array.length, 0, array.length); // Not `array.length - 1` because we want to allow moving to the end of the array

    return targetIndex < array.length
        ? array
            .slice(0, targetIndex).except(movingItems)
            .concat(movingItems)
            .concat(array.slice(targetIndex).except(movingItems))
        : array
            .except(movingItems)
            .concat(movingItems);
}

/** Range between two integers, from the lowest to the highest inclusive. */
export function rangeBetween(from: number, to: number) {
    return range(Math.min(from, to), Math.abs(to - from) + 1);
}

export function range(count: number): number[];
export function range(start: number, count: number): number[];
export function range(start: number, count?: number): number[] {
    if (count == null) {
        count = start;
        start = 0;
    }

    let array = [];
    for (let i = 0; i < count; i++)
        array[i] = i + start;
    return array;
}

export function repeat<T>(item: T, count: number): T[] {
    let array = [];
    for (let i = 0; i < count; i++)
        array.push(item);
    return array;
}

export function iterate<T>(firstItem: T, getNextItem: (item: T) => T | null | undefined): T[] {
    let array = [] as T[];
    let item = firstItem as T | null | undefined;
    do {
        array.push(item!);
        item = getNextItem(item!);
    } while (item != null);

    return array;
}

export function treePreOrder<T>(nodes: readonly T[], getChildren: (node: T) => (readonly T[] | undefined)): T[] {
    return nodes.flatMap(n => [n, ...treePreOrder(getChildren(n) || [], getChildren)]);
}

export function arraysEqual(arrays: readonly (readonly any[])[]) {
    return zip(arrays).every(items => items.every(i => i == items[0]));
}

// Typed zip functions for typed sets of arrays
export function zip<Arrays extends readonly [readonly any[], ...((readonly any[])[])]>(arrays: Arrays): ArrayValues<Arrays>[];
export function zip<Arrays extends readonly [readonly any[], ...((readonly any[])[])], Result, This = void>(arrays: Arrays, selector: (this: This, ...items: ArrayValues<Arrays>) => Result, thisArg?: This): Result[];

// Untyped zip function for arbitrary arrays
export function zip(arrays: readonly (readonly any[])[]): any[][];
export function zip<Result, This = void>(arrays: readonly (readonly any[])[], selector: (...items: any[]) => Result, thisArg?: This): Result[];

export function zip<Arrays extends readonly [readonly any[], ...((readonly any[])[])], Result, This = void>(arrays: Arrays, selector?: (this: This, ...items: ArrayValues<Arrays>) => Result, thisArg?: This) {
    selector = selector || ((...items) => items as Result);
    let maxLength = arrays.map(a => a.length).max();

    return range(maxLength)
        .map(i => selector!.apply(thisArg as This, arrays.map(array => array[i]) as ArrayValues<Arrays>));
}

type ArrayValues<Arrays> = Arrays extends [(infer First)[], ...infer Rest]
    ? [First, ...ArrayValues<Rest>]
    : [];