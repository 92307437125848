export type MaybeFunction<Args extends any[], Result> = Result | ((...args: Args) => Result);

export function negate<In, Out extends In, Rest extends any[]>(fn: (value: In, ...rest: Rest) => value is Out): (value: In, ...rest: Rest) => value is Exclude<In, Out>;
export function negate<Fn extends (...args: any[]) => boolean>(fn: Fn): Fn;
export function negate<Fn extends (...args: any[]) => boolean>(fn: Fn) {
    return function (this: any, ...args: any[]) { return !fn.apply(this, args); } as Fn;
}

export function functor<Args extends any[], Result>(value: MaybeFunction<Args, Result>): (...args: Args) => Result {
    return typeof value == 'function'
        ? value as (...args: Args) => Result
        : () => value;
}

/** Does nothing. Useful when you don't want to keep creating new empty arrow functions. */
export function noop() { }