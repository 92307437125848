import { LocalDate } from "@zap/utils/lib/DateTime";
import { SizeInBytes, SizeInGigabytes, SizeInKilobytes, SizeInMegabytes } from "@zap/utils/lib/SizeIn";

/** Override these properties with localized versions */
export const LocalText = {
    Columns: "Columns",
    Filter: "Filter",
    ClearFilters: "Clear filters",
    AllDataFilteredOut: "No items match the current filters",
    All: "All",
    Minimum: "Minimum",
    Maximum: "Maximum",
    None: "None",
    AnyDate: "Any date",
    Today: "Today",
    From: "From",
    To: "To",
    MultiSelect: "Multi-select",
    HelpLink: "More info"
};

/** Override these properties with localized versions */
export const Formatting = {
    /** Locale name (e.g. 'en-US') */
    locale: navigator.language as Intl.UnicodeBCP47LocaleIdentifier,
    formatNumber: (number: Number) => number.toLocaleString(),
    parseNumber: (number: string) => parseNumber(number, Formatting.locale),
    /** Format date for use in an input, where the date will need to be parsed again later. */
    formatDate: (date: LocalDate) => date.inUserTime().toLocaleDateString('en-US'),
    parseDate: (date: string) => Number.isNaN(Date.parse(date)) ? undefined : LocalDate.fromUserTime(new Date(date)),
}

export interface IDateFormatterOptions {
    /** Optional locale name (e.g. 'en-US'). If not specified, the current value of {@link Formatting.locale} will be used when formatting. */
    locale?: Intl.UnicodeBCP47LocaleIdentifier;
    /** Optional time zone. If not specified, the user's time zone will be used. */
    timeZone?: string;
}

/** 
 * Standard date/time formatting for UI.
 * Recommended to display a short version on screen, with the long version in a tooltip.
 **/
export class DateFormatter {
    constructor(private _options: IDateFormatterOptions = {}) { }

    /** e.g. Thursday, February 2, 2023 at 2:28:23 PM GMT+10 */
    dateTimeLong(dateTime: Date) {
        return dateTime.toLocaleString(this.locale, { dateStyle: 'full', timeStyle: 'long', timeZone: this._options.timeZone });
    }

    /** e.g. Thu, Feb 02, 21:10 */
    dateTimeShort(dateTime: Date) {
        return dateTime.toLocaleString(this.locale, { month: 'short', weekday: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false, timeZone: this._options.timeZone });
    }

    /** e.g. 2 days ago / in 2 days */
    dateTimeRelative(dateTime: Date) {
        // Ranges copied from https://momentjs.com/docs/#/displaying/fromnow/
        let ms = +dateTime - Date.now();
        let seconds = ms / 1000;
        let minutes = ms / 1000 / 60;
        let hours = ms / 1000 / 60 / 60;
        let days = ms / 1000 / 60 / 60 / 24;
        let months = ms / 1000 / 60 / 60 / 24 / 30;
        let years = ms / 1000 / 60 / 60 / 24 / 365;
        let relative = new Intl.RelativeTimeFormat(this.locale, { numeric: 'auto' });
        if (Math.abs(seconds) <= 44)
            return relative.format(0, 'minutes');
        if (Math.abs(minutes) <= 44)
            return relative.format(Math.round(minutes), 'minutes');
        if (Math.abs(hours) <= 21)
            return relative.format(Math.round(hours), 'hours');
        if (Math.abs(days) <= 25)
            return relative.format(Math.round(days), 'days');
        if (Math.abs(days) <= 319)
            return relative.format(Math.round(months), 'months');
        return relative.format(Math.round(years), 'years');
    }

    /** e.g. February 2, 2023 */
    dateLong(date: Date): string;
    dateLong(date: LocalDate): string;
    dateLong(date: Date | LocalDate) {
        return (date instanceof LocalDate
            ? date.inUserTime()
            : date
        ).toLocaleString(this.locale, { dateStyle: 'long', timeZone: this._options.timeZone });
    }

    /**
     * Returns a date similar to {@link dateTimeShort} e.g. Thu, Feb 02. 
     */
    dateShort(date: Date): string;
    dateShort(date: LocalDate): string;
    dateShort(date: Date | LocalDate) {
        let options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: '2-digit', timeZone: this._options.timeZone };
        return (date instanceof LocalDate
            ? date.inUserTime()
            : date
        ).toLocaleString(this.locale, options);
    }

    /**
     * Converts a dateTime similar to {@link dateTimeShort}
     * @param force12hr If set to true will force 12 hour time regardless of the locale's preference, otherwise will go with the locale's preference.
     */
    timeShort(dateTime: Date, force12hr: boolean = false) {
        let options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', timeZone: this._options.timeZone };

        if (force12hr)
            options.hour12 = true;

        return dateTime.toLocaleString(this.locale, options);
    }

    /**
     * To be superseded by Intl.DurationFormat when it becomes more generally available
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DurationFormat/format
     */
    durationLong(milliseconds: number) {
        if (milliseconds < 0) {
            throw new Error('Duration must be a non-negative value.');
        }

        if (milliseconds < 1000) {
            return '0 seconds';
        }

        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let remainingSeconds = seconds % 60;
        let remainingMinutes = minutes % 60;
        let remainingHours = hours % 24;

        let dayText = days > 0
            ? `${days} day${days > 1 ? 's' : ''}`
            : null;
        let hourText = remainingHours > 0
            ? `${remainingHours} hour${remainingHours > 1 ? 's' : ''}`
            : null;
        let minuteText = remainingMinutes > 0
            ? `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`
            : null;
        let secondText = remainingSeconds > 0
            ? `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`
            : null;

        let parts = [dayText, hourText, minuteText, includeSeconds(dayText, hourText) && secondText].notFalsy();

        return parts.singleOrNull() ?? buildDurationString(parts);
    }

    /**
     * To be superseded by Intl.DurationFormat when it becomes more generally available
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DurationFormat/format
     */
    durationShort(milliseconds: number) {
        if (milliseconds < 0) {
            throw new Error('Duration must be a non-negative value.');
        }

        if (milliseconds < 1000) {
            return '0s';
        }

        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let remainingSeconds = (seconds % 60);
        let remainingMinutes = minutes % 60;
        let remainingHours = hours % 24;

        let dayText = days > 0
            ? `${days}d`
            : null;
        let hourText = remainingHours > 0
            ? `${remainingHours}h`
            : null;
        let minuteText = remainingMinutes > 0
            ? `${remainingMinutes}m`
            : null;
        let secondText = remainingSeconds > 0
            ? `${remainingSeconds}s`
            : null;

        let parts = [dayText, hourText, minuteText, includeSeconds(dayText, hourText) && secondText].notFalsy();

        return parts.join(' ');
    }

    get locale() {
        return this._options.locale
            ?? Formatting.locale;
    }
}

function buildDurationString(parts: string[]) {
    let commaParts = parts.slice(0, parts.length - 1);
    return commaParts.join(', ').concat(' and ' + parts[parts.length - 1]);
}

function includeSeconds(dayText: string | null, hourText: string | null) {
    return !dayText && !hourText;
}

export const dateFormatter = new DateFormatter();

const parsers: Record<string, NumberParser> = {};

function parseNumber(number: string, locale: string) {
    let parser = parsers[locale] ??= new NumberParser(locale);
    return parser.parse(number);
}

export interface IByteFormatterOptions {
    /** Optional locale name (e.g. 'en-US'). If not specified, the current value of {@link Formatting.locale} will be used when formatting. */
    locale?: Intl.UnicodeBCP47LocaleIdentifier;
}


const units = ["byte", "kilobyte", "megabyte", "gigabyte", "terabyte", "petabyte"];
const unitBase = 1024;
const logOfUnitBase = Math.log(unitBase);
export class ByteFormatter {
    constructor(private _options: IByteFormatterOptions = {}) { }

    formatBytes(bytes: number) {
        let absoluteBytes = Math.abs(bytes);

        let unitsIndex = 0;
        let bytesExpressedInUnit = 0;

        if (absoluteBytes !== 0) {
            let maxUnitsIndex = units.length - 1;
            unitsIndex = Math.min(Math.floor(Math.log(absoluteBytes) / logOfUnitBase), maxUnitsIndex);
            bytesExpressedInUnit = absoluteBytes / Math.pow(unitBase, unitsIndex);

            //If we would round up a unit, use that instead.
            let roundedBytesExpressedInUnit = Number(bytesExpressedInUnit.toFixed(1));
            if (roundedBytesExpressedInUnit >= unitBase && unitsIndex < maxUnitsIndex) {
                bytesExpressedInUnit = bytesExpressedInUnit / unitBase;
                unitsIndex++;
            }
        }

        let unit = units[unitsIndex];

        bytesExpressedInUnit = bytes < 0 ? -bytesExpressedInUnit : bytesExpressedInUnit;

        return bytesExpressedInUnit.toLocaleString(this.locale, { style: 'unit', unit: unit, maximumFractionDigits: 2, unitDisplay: 'narrow' });
    }

    get locale() {
        return this._options.locale
            ?? Formatting.locale;
    }
}

export const byteFormatter = new ByteFormatter();

/** Based on https://observablehq.com/@mbostock/localized-number-parsing */
class NumberParser {
    private _group: RegExp;
    private _decimal: RegExp;

    constructor(locale: string) {
        let parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
        this._group = new RegExp(`[${parts.findOrThrow(d => d.type == 'group').value}]`, 'g');
        this._decimal = new RegExp(`[${parts.findOrThrow(d => d.type == 'decimal').value}]`);
    }

    parse(number: string) {
        return (number = number.trim()
            .replace(this._group, '')
            .replace(this._decimal, '.')) ? +number : NaN;
    }
}