import { functor } from "@zap/utils/lib/Function";
import { valueOf } from "@zap/utils/lib/Object";
import { combineHandlers, dataProps, nonDataProps, useMaybeControlledState, useRefCombiner, useSemiControlledState } from "@zap/utils/lib/ReactHelpers";
import { ChangeEvent, DetailedHTMLProps, FocusEvent, FocusEventHandler, InputHTMLAttributes, KeyboardEvent, ReactNode, Ref, useRef, useState } from "react";
import { Key } from "ts-key-enum";
import { Column, IBoxProps, Row, fullBleed, halfBleed } from "./Box";
import { defaultFontFamily, defaultFontSize, disabledBackground, disabledForeground, duration, errorColor, flexGrow, flexShrink, formControlBackground, formControlBorder, formControlFocus, formControlHover, formControlHoverBorder, highlightColor, roundedBorderRadius, roundedBorders, standardBorder, standardBorderColor } from "./CommonStyles";
import { DisabledContext, useDisabled } from "./Disabling";
import { IFormFieldProps, fieldAsRef, useFormContext, useFormLabelSpacing } from "./Form";
import { HelperText, IFieldHelperTextProps } from "./HelperText";
import { useLoadingDelay } from "./Loading";
import { standardFormControlHeight, subGrid } from "./Sizes";
import { IFieldUnderlineProps } from "./Underline";
import { StyleCollection, Styled, defaultPx, important, lighten, style, transition } from "./styling";
import { FocusHelper } from "./FocusHelper";
import { FormLabel } from "./FormLabel";
import { Progress } from "./Progress";

export interface IInputProps<TValue, TField extends {} = HTMLInputElement>
    extends
    Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'defaultValue' | 'onFocus' | 'onBlur' | 'ref' | 'onSubmit'>,
    IFieldUnderlineProps<TValue>,
    IFieldHelperTextProps<TValue>,
    IFormFieldProps<TField> {

    value?: TValue;
    defaultValue?: TValue;
    defaultText?: string;
    /** Typed, only called on enter and blur */
    onValueChange?(value: TValue): void;
    onFocus?: FocusEventHandler<HTMLElement>;
    onBlur?: FocusEventHandler<HTMLElement>;
    /** Called on enter, after {@link onValueChange}. */
    onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => boolean | void;
    /** Called on escape */
    onCancel?: (event: React.KeyboardEvent<HTMLElement>) => boolean | void;
    label?: string;
    forceShowValidation?: boolean;
    styles?: StyleCollection;
    grow?: boolean;
    shrink?: boolean;
    flex?: string;
    templateText?: string;
    transparent?: boolean;
    hideUnderline?: boolean;
}

export interface IInputConfig<TValue, TField extends {}> {
    type?: string;
    role?: string;
    getValue?(text: string): TValue | undefined;
    getField?(input: HTMLInputElement): TField;
    /** Allows the input to be left with an invalid value when it loses focus */
    allowInvalidValue?: boolean;
    inputRowProps?: IBoxProps;
    inputProps: IInputProps<TValue, TField>;
    getText?(value: TValue): string;
    tag?: 'input' | 'textarea';
    styles?: StyleCollection;
}

interface IInputRenderProps {
    before?: ReactNode;
    after?: ReactNode;
    outerRef?: Ref<HTMLDivElement>;
    inputRef?: Ref<HTMLInputElement>;
}

export function useInput<TValue, TField extends {} = HTMLInputElement>(inputConfig: IInputConfig<TValue, TField>) {
    let {
        type = 'text',
        role = 'textbox',
        getValue = (text: string) => text as TValue,
        getField = (input: HTMLInputElement) => input as any as TField,
        inputProps: props,
        getText = String,
        tag = 'input' as const,
        allowInvalidValue,
        styles: outerStyles,
        inputRowProps: { styles: inputRowStyles, ...inputRowOverrides } = {},
    } = inputConfig;

    let inputRef = useRef<HTMLInputElement>(null);

    let fieldRef = (input: HTMLInputElement | null) => input && fieldAsRef(props.field)(getField(input));
    let form = useFormContext();

    let { label, value, defaultValue, defaultText, width, grow, shrink, flex, className, forceShowValidation, onFocus, onBlur, onSubmit, onCancel, onChange, onValueChange, onKeyDown, templateText, placeholder, isFocused, showEmptyHelper, disabled,
        transparent, hideUnderline,
        isValid,
        isLoading: getIsLoading = false,
        styles: inputStyles,
        helperText,
        warningText,
        errorText,
        ...inputProps } = props;
    inputProps = nonDataProps(inputProps);
    disabled = useDisabled(disabled);

    let [isDirty, setDirty] = useState(false);
    let [currentValue, setCurrentValue] = useMaybeControlledState(defaultValue ?? (defaultText ? getValue(defaultText) : undefined), value);
    let [currentText, setCurrentText] = useSemiControlledState(defaultText, getText(currentValue));

    let showValidation = forceShowValidation || isDirty || form.submitted;
    let [inputElementValid, setInputElementValid] = useState(undefined as undefined | boolean);
    let appearValid = showValidation && !disabled
        ? functor(isValid)(currentValue) && (inputElementValid ?? true)
        : undefined;

    let isLoading = functor(getIsLoading)(currentValue);
    let showLoading = useLoadingDelay(isLoading);

    let hasEmptyValue = currentValue === undefined
        || typeof currentValue == 'string' && !currentValue;

    let isEmpty = hasEmptyValue
        && !props.defaultValue
        && !typeHasDefaultPlaceholder();

    let formSpacing = useFormLabelSpacing(label, true);

    let outerRefs = useRefCombiner<HTMLDivElement>();
    let inputRefs = useRefCombiner<HTMLInputElement>();

    return {
        disabled,
        render(extraProps: IInputRenderProps) {
            return <DisabledContext disabled={disabled}>
                <Styled.div
                    styles={[textbox, halfBleed, grow && flexGrow, shrink && flexShrink, formSpacing, outerStyles]}
                    className={className}
                    inline={{ width, flex }}
                    role={role}
                    onClick={() => inputRef.current?.focus()}
                    {...dataProps(props)}>
                    <FocusHelper disabled={disabled} onFocus={onFocus} onBlur={blur} isFocused={isFocused}>
                        {(focus, focusRef) =>
                            <Column positioned spacing="none" onInput={updateInputValid}>
                                <FormLabel label={label} color={labelColor(disabled, appearValid, focus.isFocused)} />
                                <Row positioned spacing="none" sidePadding
                                    minHeight={standardFormControlHeight}
                                    styles={[
                                        transparent ? transparentStyle : lightStyle,
                                        focus.isFocused && focusedStyle,
                                        appearValid === false && invalidStyle,
                                        disabled && disabledStyle,
                                        inputRowStyles
                                    ]}
                                    tabIndex={disabled ? undefined : -1}
                                    ref={outerRefs(extraProps.outerRef, focusRef)}
                                    {...focus.getFocusProps({ onFocus: onInputRowFocus })}
                                    {...inputRowOverrides}>
                                    {extraProps.before}
                                    <Styled
                                        tag={tag}
                                        type={type}
                                        ref={inputRefs(inputRef, extraProps.inputRef, fieldRef)}
                                        value={currentText}
                                        styles={[input, halfBleed, inputStyles]}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                        {...inputProps}
                                        onChange={changed}
                                        onKeyDown={combineHandlers(onKeyDown, keydown)}
                                        data-validity={appearValid ?? true}
                                        role="" />{/* prevent implicit role on the input, there's an explicit role on the label */}
                                    {!!templateText && (focus.isFocused || !isEmpty || !label)
                                        && <Styled.span styles={[templateTextStyle, halfBleed]}>{templateText}</Styled.span>}
                                    {extraProps.after}
                                </Row>
                                {!(hideUnderline && !isLoading) &&
                                    <Progress indeterminate={!disabled && showLoading} max={1} value={1}
                                        square
                                        foregroundColor={underlineColor(focus.isFocused)}
                                        backgroundColor={underlineColor(focus.isFocused) && lighten(underlineColor(focus.isFocused)!, 1.3)}
                                        data-testid={testIds.underline}
                                        styles={[
                                            underline,
                                            disabled && hiddenUnderline,
                                            (focus.isFocused || showLoading) ? thickUnderline : thinUnderline,
                                            fullBleed
                                        ]}
                                    />
                                }
                            </Column>
                        }
                    </FocusHelper>
                    <HelperText
                        disabled={disabled}
                        showEmptyHelper={showEmptyHelper}
                        helperText={functor(helperText)(currentValue)}
                        warningText={showValidation ? functor(warningText)(currentValue) : undefined}
                        errorText={showValidation ? functor(errorText)(currentValue) : undefined}
                    />
                </Styled.div>
            </DisabledContext>
        }
    };

    function underlineColor(appearFocused: boolean) {
        return appearValid === false ? errorColor
            : appearFocused || showLoading ? undefined // Default progress color
                : standardBorderColor;
    }

    function onInputRowFocus(event: FocusEvent<HTMLDivElement>) {
        if (event.target == event.currentTarget) // Only care when the row itself is getting focus
            queueMicrotask(() => inputRef.current?.focus()); // Wait for this event to complete before moving focus
    }

    function changed(event: ChangeEvent<HTMLInputElement>) {
        setCurrentText(event.target.value);
        setDirty(true);
        onChange?.(event);
    }

    function keydown(event: KeyboardEvent<HTMLInputElement>) {
        setDirty(true);

        if (event.key == Key.Enter) {
            updateValue(event.currentTarget);
            if (onSubmit?.(event) !== false)
                form.submit();
        } else if (event.key == Key.Escape) {
            if (onCancel?.(event) !== false) // TODO remove boolean return with old form
                form.cancel();
        }
    }

    function blur(event: FocusEvent<HTMLInputElement>) {
        updateValue(inputRef.current!);
        setDirty(true);
        onBlur?.(event);
    }

    function updateInputValid() {
        if (inputRef.current)
            setInputElementValid(inputRef.current.checkValidity());
    }

    function updateValue(input: HTMLInputElement) {
        fieldRef(input);

        let value = getValue(input.value);
        if (value === undefined) {
            if (!allowInvalidValue)
                setCurrentText(getText(currentValue));
        } else {
            setCurrentValue(value);
            if (valueOf(value) !== valueOf(currentValue))
                onValueChange?.(value);
            setCurrentText(getText(value));
        }
    }

    function typeHasDefaultPlaceholder() {
        switch (props.type) {
            case 'date':
            case 'datetime-local':
            case 'month':
            case 'time':
            case 'week':
                return true;
            default:
                return false;
        }
    }
}

function labelColor(disabled: boolean | undefined, isValid: boolean | undefined, isFocused: boolean) {
    return disabled
        ? disabledForeground
        : validityColor(isValid) ?? focusedColor(isFocused);
}

function validityColor(isValid?: boolean) {
    return isValid === false
        ? errorColor
        : undefined;
}

function focusedColor(isFocused: boolean) {
    return isFocused
        ? highlightColor
        : undefined;
}

export const testIds = {
    underline: 'underline'
}

let textbox = style('textbox', {
    display: 'inline-block',
    position: 'relative'
});

let focusedStyle = style('is-textbox-focused');

let lightStyle = style('textbox-light', {
    ...roundedBorders,
    background: formControlBackground,
    outline: standardBorder(formControlBorder), // Outline because all the "standard form control height" elements are inside this, and don't want to add to the size
    outlineOffset: -1, // On the inside like a border
    ':hover': {
        background: formControlHover,
        outlineColor: formControlHoverBorder
    },
    $: {
        [`&.${focusedStyle}`]: {
            background: formControlFocus
        }
    }
});

let transparentStyle = style('textbox-transparent', {
    background: 'transparent'
});

let invalidStyle = style('is-textbox-invalid', {
    outlineColor: errorColor,
    ':hover': {
        outlineColor: errorColor
    }
});

let disabledStyle = style('is-textbox-disabled', {
    color: disabledForeground,
    background: important(disabledBackground),
    outlineColor: important(disabledBackground)
});

export const textBoxLineHeight = 20;
export const textBoxVerticalPadding = (standardFormControlHeight - textBoxLineHeight) / 2;

let input = style('textbox-input', {
    ...defaultFontSize,
    ...defaultFontFamily,
    border: 'none',
    outline: 'none',
    flex: 1,
    width: 0,
    minHeight: standardFormControlHeight,
    padding: [textBoxVerticalPadding, 0],
    lineHeight: defaultPx(textBoxLineHeight),
    overflow: 'hidden',
    background: 'transparent',
    $: {
        '::-webkit-search-cancel-button': {
            display: 'none'
        }
    }
});

let templateTextStyle = style('textbox-template', {
    color: disabledForeground,
    height: standardFormControlHeight,
    lineHeight: `${standardFormControlHeight}px`
});

export let endIcon = style('textbox-endIcon', {
    paddingRight: subGrid
});

let underline = style('underline', {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: [0, 0, roundedBorderRadius, roundedBorderRadius],
    overflow: 'hidden',
    transition: transition('height', duration.small, 'ease'),
    transform: { translateZ: 0 } // Avoids a Chrome bug where it would render an extra pixel at either end of the underline, outside the border radius
});

let thinUnderline = style('is-underline-thin', {
    height: important(1),
    left: 1,
    right: 1
});

let thickUnderline = style('is-underline-thick', {
    height: important(2)
});

let hiddenUnderline = style('is-underline-hidden', { visibility: 'hidden' });