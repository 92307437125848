import { noop } from "@zap/utils/lib/Function";

const splashClass = 'splash';
const arrowClass = 'arrow';
const isQuick = 'is-splash-quick';
const isArrowOnly = 'is-splash-arrowOnly';
const isInsideOut = 'is-splash-insideOut';
const isEnding = 'is-splash-end';
const isHidden = 'is-splash-hidden';
const isEntering = 'is-splash-enter';
const isPaused = 'is-splash-paused';

export function hideSplash({
    container = document.body,
    insideOut = false,
    instant = false,
    onHidden = noop
} = {}) {
    let splash = container.getElementsByClassName(splashClass)[0] as HTMLElement;
    if (!splash)
        return;

    if (instant)
        hideSplashElement();
    else if (container.classList.contains(isQuick) || container.classList.contains(isArrowOnly) || splash.classList.contains(isPaused))
        endSplash();
    else
        splash.getElementsByClassName(arrowClass)[0].addEventListener('animationiteration', endSplash, { once: true });

    function endSplash() {
        if (insideOut)
            splash.classList.add(isInsideOut);
        splash.classList.add(isEnding);
        splash.addEventListener('animationend', onAnimationComplete);
    }

    function onAnimationComplete(e: AnimationEvent) {
        if (e.animationName == 'splash-background-end' || e.animationName == 'splash-background-endInsideOut')
            hideSplashElement();
    }

    function hideSplashElement() {
        container.classList.add(isHidden);
        clearHandlers();
        onHidden();
    }

    function clearHandlers() {
        splash.getElementsByClassName(arrowClass)[0]?.removeEventListener('animationiteration', endSplash);
        splash.removeEventListener('animationend', onAnimationComplete);
    }
}

export function showSplash({
    container = document.body,
    quick = false,
    arrowOnly = false,
    paused = false
} = {}) {
    let splash = container.getElementsByClassName(splashClass)[0] as HTMLElement;
    if (!splash)
        return;

    container.classList.remove(isHidden);
    container.classList.toggle(isQuick, quick);
    container.classList.toggle(isArrowOnly, arrowOnly);
    splash.classList.toggle(isPaused, paused);
    splash.classList.add(isEntering);
    splash.classList.remove(isEnding, isInsideOut);
}