import { IChildren, RefFn, combineRefs } from "@zap/utils/lib/ReactHelpers";
import { Ref, RefObject, createContext, forwardRef, useContext, useEffect, useMemo, useRef } from "react";
import { Key } from "ts-key-enum";
import { closestFocusableElement, focusableElementsIn } from "./AutoFocus";
import { DomRegistrar, IDomRegistrar, IDomScope, useDomScope } from "./DomScope";
import { Wrapper } from "./Wrapper";

export interface IFocusScopeProps extends IFocusScopeConfig, IChildren { }

export interface IFocusScopeConfig {
    wrap?: boolean;
    scroll?: boolean;
    restoreFocus?: boolean;
    autoFocus?: boolean;
    onFocusIn?(event: React.FocusEvent<HTMLElement>): void;
    onFocusOut?(event: React.FocusEvent<HTMLElement>, direction: FocusDirection): void;
}

export type FocusDirection = 'none' | 'forward' | 'backward';

export const FocusScope = forwardRef(function FocusScope({ children, ...config }: IFocusScopeProps, ref: Ref<HTMLElement>) {
    let focus = useFocusScope(config);
    return <DomRegistrar.Provider value={focus.domRegistrar}>
        <FocusControl.Provider value={focus.control}>
            <Wrapper ref={combineRefs(ref, focus.ref)} {...focus.eventHandlers}>
                {children}
            </Wrapper>
        </FocusControl.Provider>
    </DomRegistrar.Provider>
});

function useFocusScope({ wrap = false, scroll = false, restoreFocus, autoFocus, onFocusIn, onFocusOut }: IFocusScopeConfig): IFocusScope {
    let root = useRef<HTMLElement>(null); // Used for cycling between focusable elements
    let scope = useDomScope(); // Used for determining when focus enters/leaves scope (includes portals)

    let control = useMemo<IFocusControl>(() => createFocusControl({ root, scope, wrap, scroll }), [wrap, scroll]);

    let tabDirection = useRef<FocusDirection>('none');

    let previousFocus = useRef<Element | null>(null);

    useEffect(() => {
        previousFocus.current = document.activeElement;

        if (autoFocus)
            control.focusFirst();

        return () => {
            if (restoreFocus && previousFocus.current instanceof HTMLElement && previousFocus.current.isConnected)
                previousFocus.current.focus({ preventScroll: true });
        }
    }, []);

    let ref = combineRefs(root, scope.registrar.useChildRef());

    let eventHandlers = useMemo(() => ({
        onMouseDownCapture(event: React.MouseEvent<HTMLElement>) {
            // Ignore mousedown inside scope but outside focusable elements, to avoid losing focus
            if (!isInsideFocusScope(scope, closestFocusableElement(event.target as HTMLElement)))
                event.preventDefault();
        },

        onKeyDownCapture(event: React.KeyboardEvent<HTMLElement>) {
            if (event.key == Key.Tab)
                tabDirection.current = event.shiftKey ? 'backward' : 'forward';
        },

        onFocus(event: React.FocusEvent<HTMLElement>) {
            if (!isInsideFocusScope(scope, event.relatedTarget)) {
                if (tabDirection.current == 'none') // Avoids calling onFocusIn when focus is being wrapped
                    onFocusIn?.(event);
                else
                    tabDirection.current = 'none';
            }
        },

        onBlur(event: React.FocusEvent<HTMLElement>) {
            if (isInsideFocusScope(scope, event.relatedTarget)) {
                tabDirection.current = 'none';
            } else if (root.current) { // Ignore if unmounted (TODO use ref cleanup function with react 19)
                if (wrap && tabDirection.current != 'none') {
                    queueMicrotask(() => { // Wait for this event to complete before moving focus
                        if (tabDirection.current == 'forward')
                            control.focusFirst();
                        else
                            control.focusLast();
                    });
                } else {
                    onFocusOut?.(event, tabDirection.current);
                    tabDirection.current = 'none';
                }
            }
        }
    }), [wrap]);

    return { ref, domRegistrar: scope.registrar, control, eventHandlers };
}

export interface IFocusScope {
    ref: RefFn<HTMLElement>;
    domRegistrar: IDomRegistrar;
    control: IFocusControl;
    eventHandlers: {
        onMouseDownCapture: React.MouseEventHandler<HTMLElement>,
        onKeyDownCapture: React.KeyboardEventHandler<HTMLElement>,
        onFocus: React.FocusEventHandler<HTMLElement>,
        onBlur: React.FocusEventHandler<HTMLElement>
    };
}

export function useFocusControl() { return useContext(FocusControl); }

function createFocusControl({ root, scope, wrap, scroll }: { root: RefObject<HTMLElement>; scope: IDomScope; wrap?: boolean; scroll?: boolean; }): IFocusControl {
    return {
        focusNext: () => shiftFocus(1),
        focusPrevious: () => shiftFocus(-1),
        focusFirst: () => focus(focusableElementsIn(root.current)[0]),
        focusLast: () => focus(focusableElementsIn(root.current).lastOrNull()),
        blur() {
            if (isInsideFocusScope(scope, document.activeElement))
                (document.activeElement as HTMLElement)?.blur();
        }
    }

    function shiftFocus(shift: -1 | 1) {
        let focusable = focusableElementsIn(root.current);
        let currentIndex = focusable.indexOf(document.activeElement as HTMLElement);
        let nextIndex = currentIndex + shift;
        if (wrap)
            nextIndex %= focusable.length;

        return focus(focusable[nextIndex])
            && nextIndex != currentIndex;
    }

    function focus(element: HTMLElement | null | undefined) {
        return !!element?.focus({ preventScroll: !scroll });
    }
}

function isInsideFocusScope(scope: IDomScope, target: EventTarget | null) {
    return target instanceof HTMLElement
        && scope.elements.some(e => e.contains(target));
}

export interface IFocusControl {
    /** Returns true if another element was focused. */
    focusNext(): boolean;
    /** Returns true if another element was focused. */
    focusPrevious(): boolean;
    /** Returns true if another element was focused. */
    focusFirst(): boolean;
    /** Returns true if another element was focused. */
    focusLast(): boolean;
    blur(): void;
}

export const FocusControl = createContext<IFocusControl>({
    focusNext: () => false,
    focusPrevious: () => false,
    focusFirst: () => false,
    focusLast: () => false,
    blur() { }
});