import { extendPrototype } from "./ExtensionHelpers";

let mapExtensions = {
    getOrAdd,
    toObject
};
extendPrototype(Map, mapExtensions);

type MapExtensions = typeof mapExtensions;
declare global { interface Map<K, V> extends MapExtensions { } }

// Implementations below:

export function getOrAdd<K, V>(this: Map<K, V>, key: K, valueFactory: (key: K) => V): V {
    if (this.has(key))
        return this.get(key)!;
    let value = valueFactory(key);
    this.set(key, value);
    return value;
}

export function toObject<K, V>(this: Map<K, V>, keySelector: (key: K) => PropertyKey): { [key: PropertyKey]: V };
export function toObject<V>(this: Map<PropertyKey, V>): { [key: PropertyKey]: V };
export function toObject<K, V>(this: Map<K, V>, keySelector: (key: K) => PropertyKey = String): { [key: PropertyKey]: V } {
    let obj = {} as { [key: PropertyKey]: any };
    this.forEach((value, key) => obj[keySelector(key)] = value);
    return obj;
}