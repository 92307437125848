import { Center, Column } from "@zap/ui/lib/Box";
import "../../../../SharedSource/Web.Core/Client/Text";
import { reactive } from "event-reduce-react";
import { style } from "@zap/ui/lib/styling";
import { EmbeddedLoginModel, LoginState } from "./EmbeddedLoginModel";

export interface ISeamlessSsoBootstrapProps {
    model: EmbeddedLoginModel;
    returnUrl: string;
    challengeUrl: string;
}

export const SeamlessSsoBootstrap = reactive(function SeamlessSsoBootstrap({ model, returnUrl, challengeUrl }: ISeamlessSsoBootstrapProps) {
    return <Center fit styles={containerStyles}>
        <Column>
            {model.loginState == LoginState.SsoLoggingIn
                ? PageText.Label_Loading
                : ''}
        </Column>
        <iframe
            src={challengeUrl}
            style={{ display: 'none' }}
            onLoad={event => {
                try {
                    const loadedUrl = event.currentTarget.contentWindow!.location.href;
                    if (loadedUrl == returnUrl)
                        location.href = returnUrl;
                    else
                        model.events.ssoLoginFailed(); // so that we can show the login form
                } catch (e) {
                    // if the loaded url in the iframe is cross-origin, we'll get a security DOMException
                    model.events.ssoLoginFailed();
                }
            }}
            onError={() => model.events.ssoLoginFailed()} />
    </Center>
});

let containerStyles = style('container', {
    background: 'white'
});
