import { refFn, RefFn, useLazyRef } from "@zap/utils/lib/ReactHelpers";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { relativeDocumentPosition } from "./DomHelpers";

type OneOrMoreElements = HTMLElement | ElementSet;
type ElementSet = Set<OneOrMoreElements>;

export interface IDomScope {
    elements: HTMLElement[];
    registrar: IDomRegistrar;
}

export function useDomScope(): IDomScope {
    let outerRegistrar = useContext(DomRegistrar);

    let elements = useLazyRef(() => new Set<OneOrMoreElements>()).current;

    let registrar: IDomRegistrar = useMemo(() => ({
        register(element: OneOrMoreElements | undefined) {
            if (element)
                elements.add(element);
        },
        deregister(element: OneOrMoreElements | undefined) {
            if (element)
                elements.delete(element);
        },
        useChildRef() {
            return domScopeChildRef(this);
        }
    }), []);

    useEffect(() => {
        outerRegistrar.register(elements);
        return () => outerRegistrar.deregister(elements);
    }, []);

    return useMemo(() => ({
        get elements() {
            return allElements(elements)
                .filter(e => e.isConnected)
                .sort(relativeDocumentPosition);
        },
        registrar
    }), []);
}

function allElements(elements: OneOrMoreElements): HTMLElement[] {
    if (elements instanceof Set)
        return Array.from(elements).flatMap(allElements);
    return [elements];
}

export function useDomScopeChild() {
    return domScopeChildRef(useContext(DomRegistrar));
}

function domScopeChildRef(registrar: IDomRegistrar) {
    let childRef = useRef<HTMLElement>(null!);
    useEffect(() => {
        let childElement = childRef.current;
        registrar.register(childElement);
        return () => registrar.deregister(childElement);
    });
    return refFn(childRef);
}

export interface IDomRegistrar {
    register(element: OneOrMoreElements | undefined): void;
    deregister(element: OneOrMoreElements | undefined): void;
    useChildRef(): RefFn<HTMLElement>;
}

export const DomRegistrar = createContext<IDomRegistrar>({ register() { }, deregister() { }, useChildRef: () => () => { } });