import { colorSchemes, darkBarbsColor, defaultFont, highlightColor, iconComponentColor, iconPrimaryColor, iconSecondaryColor, linkColor, linkHoverColor } from "./CommonStyles";
import { Rules, Styles, cssRules } from "./styling";

/** Styles to be applied to the top-level element. */
export const rootStyles: Styles = {
    ...defaultFont,
    $: {
        '*': {
            boxSizing: 'border-box',
            flexShrink: 0,
            '::before': { boxSizing: 'border-box' },
            '::after': { boxSizing: 'border-box' }
        },
        ':focus': {
            outlineColor: highlightColor,
        },
        a: {
            color: linkColor,
            textDecoration: 'none',
            ':hover': {
                color: linkHoverColor
            }
        }
    }
};

export const colorSchemeStyles: Styles = {
    ...colorSchemes.orange,
    $: {
        ...Object.fromEntries(Object.entries(colorSchemes).map(([name, scheme]) => [`&.scheme-${name}`, scheme])),
        '.icon-primary': { fill: iconPrimaryColor },
        '.icon-secondary': { fill: iconSecondaryColor },
        '.icon-component': { fill: iconComponentColor },
        '.icon-barbs': { fill: darkBarbsColor },
    }
}

/** Default rules for the page. */
export const pageStyles: Rules = {
    html: {
        height: '100%'
    },
    body: {
        margin: 0,
        height: '100%'
    },
    '#react-app': {
        height: '100%'
    }
};

export function applyCommonGlobalStyles() {
    cssRules(pageStyles);
    cssRules({ body: rootStyles });
    cssRules({ body: colorSchemeStyles });
}
