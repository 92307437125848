const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

export type Uuid = string;
export const Uuid = {
    empty: '00000000-0000-0000-0000-000000000000',
    regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    create() {
        return template.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    isValid(input: string) {
        return this.regex.test(input);
    }
};