import { MaybeFunction } from "@zap/utils/lib/Function";
import { Flip, FlipScope } from "flippo-react";
import { ReactNode } from "react";
import { noSpacing } from "./Box";
import { disabledForeground, errorColor, fontSizes, labelFont, lightFontColor, warningColor } from "./CommonStyles";
import { useDisabled } from "./Disabling";
import { standardLabelStyle } from "./StandardLabel";
import { Tooltip } from "./Tooltip";
import { Styled, style } from "./styling";

export interface IFieldHelperTextProps<T> {
    showEmptyHelper?: boolean;
    helperText?: MaybeFunction<[T], ReactNode | undefined>;
    warningText?: MaybeFunction<[T], ReactNode | undefined>;
    errorText?: MaybeFunction<[T], ReactNode | undefined>;
}

export interface IHelperTextProps {
    showEmptyHelper?: boolean;
    helperText?: ReactNode;
    warningText?: ReactNode;
    errorText?: ReactNode;
    disabled?: boolean;
}

export function HelperText(props: IHelperTextProps) {
    let text = props.errorText
        ?? props.warningText
        ?? props.helperText;

    let show = !!text || props.showEmptyHelper;

    let disabled = useDisabled(props.disabled);

    let color = disabled ? disabledText
        : props.errorText ? errorText
            : props.warningText ? warningText
                : defaultText;

    return <Flip enter={false} update={{ styles: { height: true, minHeight: true } }}>
        <Tooltip content={text} >
            <Styled.div styles={[color, labelFont, helperTextStyle, color, show ? showHelper : hideHelper, !show && noSpacing]}>
                <FlipScope enter={false}>
                    {!!text && <Flip>
                        <Styled.div styles={[labelFont, color]}>{text}</Styled.div>
                    </Flip>}
                </FlipScope>
            </Styled.div>
        </Tooltip>
    </Flip>
}

let errorText = style('helper-error', { color: errorColor });
let warningText = style('helper-warning', { color: warningColor });
let defaultText = style('helper-default', { ...lightFontColor });
let disabledText = style('helper-disabled', { color: disabledForeground });

let showHelper = style('is-helper-visible', {
    ...standardLabelStyle(fontSizes.label, 'unset'),
    minHeight: 17 // height of a single line of text
});

let hideHelper = style('is-helper-hidden', {
    height: 0
});

let helperTextStyle = style('helper-text', {
    userSelect: 'none'
});